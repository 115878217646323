import styled from "styled-components";

export const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  @media (max-width:930px){
    flex-direction: column;
    width: 100%;
  }
`;
