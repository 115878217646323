import React, {useContext} from "react";
import {AppContext} from '../../../../Context/Context'
import {copy} from '../../../../Helpers/CopyText'
import FormikControl from "../FormikControl";
import { FormGroup } from "./Styles/HelperComponents";


const validateProperty = (value) => {
  let error;
  if(value === ''){
    error = 'Property Status Required'
  }
  return error;
}

function MortgageInformation(props) {

  let context = useContext(AppContext)
  let language = context.language
  let copyText = copy(language)
  
  const statusOptions = [
    // { key: "Select One", value: "" },
    { key: "Rent", value: "rent" },
    { key: "Own w/ Mortgage", value: "own_with_mortgage" },
    { key: "Own", value: "own_outright" },
    { key: "Other", value: "unknown" },
  ];

  if(props.single){
    return (
      <FormGroup>
        <FormikControl
        extraClass="single-select"
        control="select"
        options={statusOptions}
        label={copyText.mortgageInformation.label1}
        name="propertyStatus"
        validate={validateProperty}
      />
      </FormGroup>
    )
  }
  
  return (
    <>
      <FormikControl
        control="select"
        options={statusOptions}
        label={copyText.mortgageInformation.label1}
        name="propertyStatus"
        validate={validateProperty}
      />
    </>
  );
}

export default MortgageInformation;
