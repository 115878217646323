import React, {useContext} from "react";

import { FooterSection, Title, List, Item, Icon } from "../Styles/Footer";

import { MdEmail } from "react-icons/md";

import {AppContext} from '../../../Context/Context'

import {copy} from '../../../Helpers/CopyText'


function ContactUs() {

  let context = useContext(AppContext)
  let language = context.language

  let copyText = copy(language)

  return (
    <FooterSection>
      <Title>{copyText.contactUs.title}</Title>
      <List>
        <Item large>
          <Icon href="mailto:support@figloans.com"><MdEmail /></Icon>
        </Item>
      </List>
    </FooterSection>
  );
}

export default ContactUs;
