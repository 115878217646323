import styled from 'styled-components'

import {
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

export const Header = styled.h2``;

export const BodyText = styled.p``;

export const BodyHeading = styled.h3``;

export const PolicySection = styled.div`
    padding: 1rem;
    margin: 1rem 0;
    border: 1px solid #334d5d;
`;

export const SectionButton = styled.button`
    border-style: none;
    background-color: #334d5d;
    color: white;

    &:hover {
        cursor: pointer;
    }
`;

export const AiH = styled(AccordionItemHeading)`
    font-size: 1.3rem;
`

export const AiB = styled(AccordionItemButton)`
    background-color: #eef7ef;
    margin: 1rem 0;
    box-shadow: 0 0 4px gray;
    padding: 1rem .5rem;

    &:hover {
        cursor: pointer;
        background-color: #3fb39d;
        color: white;
    }
`;

export const AiP = styled(AccordionItemPanel)`
    font-size: 1rem;
    background-color: #eef7ef;
    margin: 1rem 0;
    box-shadow: 0 0 4px gray;
    padding: 1rem .5rem;

`;