import React from "react";

import { ErrorMessage } from "formik";
import TextError from "./TextError";

import "react-datepicker/dist/react-datepicker.css";
import DateView from "react-datepicker";

import { Field } from "formik";

function Date(props) {
  const { name, label, validate, ...restProps } = props;
  return (
    <div className="form-control">
      <label className="form-label" htmlFor={name}>
        {label}
      </label>
      <Field name={name} validate={validate}>
        {({ field, form }) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <DateView
              id={name}
              {...field}
              {...restProps}
              selected={value}
              showMonthDropdown
              showYearDropdown
              onChange={(val) => setFieldValue(name, val)}
              dateFormat="yyyy/MM/dd"
              placeholderText="YYYY/MM/DD"
            />
          );
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

export default Date;
