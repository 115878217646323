import styled from "styled-components";

export const QuestionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 1rem;

  @media(max-width: 420px){
    background-color: white;
    margin: 1rem auto;
    box-shadow: 0 0 5px lightgray;
  }
`;

export const Question = styled.p`
  font-size: 1.2rem;
  text-align: left;
  width: 100%;
  margin-left: 2rem;
  color: #334d5d;

  &:hover {
    cursor:pointer;
  }

  @media (max-width: 600px){
    margin-left: 0;
    text-align: center;
  }
`;

export const Answer = styled.p`
  width: 80%;
  margin-left: 2rem;
  color: gray;
  font-size: 1.2rem;

  @media (max-width: 600px){
    margin-left: 0;
  }

  @media(max-width: 420px){
    text-align: center;
    margin: 1rem auto;
  }
`;
