export const copy = (language) => {
        const english = {
            header : {
                logo : 'Marketplace',
                nav : {
                    nav1: 'Loans',
                    nav2: 'Faq',
                    nav3: 'Reviews',
                    nav4: 'Blog'
                }
            },
            appflow : {
                header: 'How does it work?',
                flow1 : 'Fill out one of our simple applications',
                flow2 : 'Get matched with competitive offers custom tailored for you.',
                flow3 : 'Choose the offer that best suits your current needs.'
            },
            scenarios : {
                header : 'Get matched with personalized offers',
                header2: 'perfect for every occasion.',
                button : 'Start Now!'
            },
            faq : {
                header : 'Commonly Asked Questions:',
                question1: {
                    question: 'What is Fig Marketplace?',
                    answer: 'Fig Marketplace is considered a service / product based marketplace. We provide our customers with competitive offers for a range of different services. Our goal is to help pair you with lenders that you can trust.'
                },
                question2: {
                    question: 'Can I qualify for a loan?',
                    answer: 'We’ll search the country for your best options. Even if you have bad credit, there are options out there. Just start an application today!'
                },
                question3: {
                    question: 'After I check my rate, what happens?',
                    answer: `After you check your rate it's quite simple. Choose the offer that makes the most sense for you, complete and verify your information, get your funds as soon as the next business day.`
                },
                question4: {
                    question: 'Will this affect my credit?',
                    answer: 'No. We do not do a hard pull on your credit when finding offers for you!'
                },
                cta: {
                    cta1 : "Now that we've answered your questions,",
                    cta2 : 'what are you waiting for?'
                },
                button : 'Start Now!'
            },
            blog : {
                header : 'Learn from our team of experts :',
                linkText : 'Read more!',
                post1 : {
                    title: 'What is "Interest" anyways?',
                    body: 'What is so interesting about interest, you may ask yourself. First of all, I will not apologize for that or any other puns. Secondly, although interest has many uses in the financial world',
                },
                post2 : {
                    title: 'What is an “Installment Loan”? Fig Answers!',
                    body: 'An installment loan gets its name from the payment type: you repay your loan with a number of installments, rather than paying it off all at once. The number of installment',
                },
                post3 : {
                    title: 'How Long Does It Take to Build Credit?',
                    body: 'The consensus from credit bureaus and lenders is that it takes at least 6 months to build up to a good credit score. While this is generally true',
                },
            },
            decline : {
                text : 'We were unable to get you pre-approved for a loan offer, but here are other products to help you.'
            },
            nav : {
                link1 : 'Loans',
                link2: 'Savings',
                link3 : 'FAQ',
                link4: 'Reviews',
                link5: 'Blog',
                homeLink: 'Home',
            },
            reviews : {
                title : "We've already helped thousands, you're next."
            },
            aboutUs : {
                title : 'Services.',
                link1: 'Loans',
                link2: 'Savings',
                link3: 'Credit Cards',
                link4: 'Refinancing',
            },
            contactUs: {
                title: 'Get in touch.'
            },
            followUs: {
                title: 'Follow Us.'
            },
            footer : {
                link1 : 'Disclosures',
                link2 : 'Privacy Policy',
                link3 : 'Terms',
                link4 : 'Do not sell'
            },
            hamburger : {
                link1 : 'Loans',
                link2 : 'Reviews',
                link3 : 'FAQ',
                link4: 'Blog',
            },
            productSelector : {
                title: 'I am looking for...',
                select1: 'a personal loan.',
                select2: 'a savings account.',
                select3: 'life insurance.',
                select4: 'a credit card.',
                select5: 'student loan refinancing.',
            },
            hero : {
                tag1 : 'Find a personal loan',
                tag2 : 'Find a savings account',
                tag3: 'Find life insurance',
                tag4 : 'Coming Soon!',
                tag5: 'Coming Soon!',
                tagEnd : 'in as little as 5 minutes',
                button1: 'Check Your Rate',
                button2: 'Find Your Account',
                button3: 'Get Quotes',
                button4: 'Coming Soon!',
                button5: 'Coming Soon!',
            },
            loanCalculator : {
                label1 : 'Loan Amount',
                label2 : 'Loan Term',
                option1: 'Year(s)',
                option2: 'Months',
                label3 : 'Interest Rate',
                button : 'Calculate',
                title1: 'Monthly Payment',
                title2: 'Principal Paid:',
                title3: 'Interest Paid:',
                education : {
                    para1: "Without taking a loan, many of us would not be able to buy a home, purchase a new vehicle or pursue higher education. The importance of loans in today's society is huge, especially when considering the average debt amongst americans is nearly $90,000!",
                    para2: "Unfortunately, more often than not, it is said that financial literacy in schooling is under taught.",
                    para3: "There are a lot of factors that go into accurately calculating the cost of a loan. Some of these include the type of loan, the lender, your credit history and believe it or not even the market itself.",
                    para4: "Searching for a loan can be confusing and oftentimes misleading. It is important that as a consumer you prioritize education before taking on debt of any kind. Using a loan calculator can help you to visualize the economic impact that a loan may have, which can save you countless headaches in the future.",
                    para5: "Our calculator leaves out the fluff and offers you a clear and concise overview of what a fixed loan will look like given your circumstances.",
                    para6: "Loans can either be secured or unsecured. A secured loan means that the buyer has to put up some type of asset as collateral to secure the loan, hence the name. A great example of a secured loan is an auto loan. When you fail to pay for an Auto loan, the lender will collect your vehicle as collateral.",
                    heading1: "Quick Tip:",
                }
            },
            staticForm : {
                title1: 'Find a ',
                title2: 'personal loan.'
            },
            loanDisplay: {
                title1: "Congratulations!",
                title2: "You've received ",
                title3: ' offer'
            },
            californiaPop : {
                text1: 'We use cookies on this site to enhance your user experience.',
                text2: 'By continuing you are accepting our ',
                text3: 'Cookie Policy',
                text4: ' as well as our ',
                text5: 'Privacy Policy',
            },
            doNotSell : {
                title: 'Do Not Sell My Info',
                text1: 'The California Consumer Protection Act (CCPA) is a California regulation which protects the rights of data subjects who are residents of the state of California, with respect to the processing of their personal data as such term is defined in the CCPA.',
                text2: 'There is no need to opt-out of the sale of your data because Fig Tech, Inc. does not sell visitor or customer data. However, if you would like to exercise your rights, please use the toggle below.',
                text3: 'Share My Data / Information',
                button1: 'Enabled',
                button2: 'Disabled',
            },
            feedbackModal : {
                tag : 'Questions?',
                label1: 'Name:',
                label2: 'Email:',
                label3: 'Comments:',
                button1: 'Submit'
            },
            // Form Copy
            loanInformation : {
                tag: 'Tell us a little about your loan requirements',
                label1: 'Loan Amount',
                label2: 'Purpose of Loan'
            },
            creditInformation : {
                tag: "Let's make sure that we pair you with the most competitive offer we can find.",
                label1: 'Credit Rating',
            },
            creditCardInformation: {	
                tag: "Tell us more about what you're looking for.",	
                label1: 'Primary Card Purpose',	
                label2: 'Allow Annual Fee?',	
            },	
            savingsInformation : {	
                tag: "How much are you hoping to set your account up with?",	
                label1: 'Deposit Amount',	
            },
            name : {
                tag: 'We want to get to know you!',
                label1: 'First Name',
                label2: 'Last Name'
            },
            address : {
                tag: 'So far so good! Where do you call home?',
                label1: 'Address 1',
                label2: 'Address 2',
                label3 : 'City',
                label4 : 'Zip Code',
                label5 : 'State',
            },
            contact : {
                tag: 'How can we get in touch with you?',
                label1: 'Email',
                label2: 'Primary Phone'
            },
            personalInfo : {
                tag: 'We use cutting edge technologies to keep your data safe.',
                label1: 'Date of Birth',
                label2: 'SSN #',
                label3: 'Citizenship Status'
            },
            mortgageInformation : {
                tag: 'Tell us about the place you call home',
                label1: 'Do You Rent or Own?',
            },
            financialInformation : {
                tag: 'What do you do for a living?',
                label1: 'Employment Status',
                label2: 'Pay Schedule',
                label3 : 'Annual Income',
                label4: 'Monthly Housing Payment',
                label5: 'Available Assets'
            },
            educationInformation : {
                tag: 'Home stretch! Personalized offers are right around the corner!',
                label1 : 'Highest Level of Education',
                label2 : 'Graduation Date',
                label3: 'University Attended',
                label4: 'Degree Type',
            },
            legalInformation : {
                tag: "Final step, we prefer transparency so here's everything all at once!",
            },
            formTitle : {
                personalLoan : 'Find a personal loan.',
                studentRefi: 'Secure your best rate.',
                creditCard: 'Find the perfect card.',	
                savingsAccount: 'Find a savings account.'
            }

        }
          const spanish = {
                header : {
                    logo : 'Marketplace',
                    nav : {
                        nav1: 'Empréstito',
                        nav2: 'Faq',
                        nav3: 'Reseñas',
                        nav4: 'Blog'
                    }
        
                },
                appflow : {
                    header: '¿Como funciona?',
                    flow1 : 'Complete una solicitud sencillo',
                    flow2 : 'Obtenga ofertas competitivas personalizadas para usted.',
                    flow3 : 'Elija la oferta que mejor se adapte a sus necesidades actuales.'
                },
                scenarios : {
                    header : 'Haz coincidir con ofertas personalizadas',
                    header2: 'perfecto para cada ocasión',
                    button : '¡Empezar ahora!'
                },
                faq : {
                    header : 'Preguntas más frecuentes:',
                    question1: {
                        question: '¿Qué es Fig Marketplace?',
                        answer: 'Fig Marketplace se considera un servicio / mercado de productos. Ofrecemos a nuestros clientes ofertas competitivas para una gama de diferentes servicios. Nuestro objetivo es ayudarle a emparejarlo con prestamistas en los que puede confiar.'
                    },
                    question2: {
                        question: '¿Puedo calificar para un préstamo?',
                        answer: 'Buscaremos en el país sus mejores opciones. Incluso si usted tiene mal crédito, hay opciones por ahí. ¡Simplemente inicie una aplicación hoy!'
                    },
                    question3: {
                        question: 'Después de revisar mi tarifa, ¿qué pasa?',
                        answer: `Después de comprobar su tarifa es bastante simple. Elija la oferta que tenga más sentido para usted, complete y verifique su información, obtenga sus fondos tan pronto como el siguiente día hábil.`
                    },
                    question4: {
                        question: '¿Afectará esto a mi crédito?',
                        answer: 'No. ¡No hacemos un duro tirón en su crédito al encontrar ofertas para usted!'
                    },
                    cta: {
                        cta1 : "Ahora que hemos respondido a sus preguntas,",
                        cta2 : '¿A qué esperas?'
                    },
                    button : '¡Empezar Ahora!'
                },
                blog : {
                    header : 'Aprenda de nuestro equipo de expertos',
                    linkText : 'Leer más!',
                    post1 : {
                        title: 'What is "interest" anyways?',
                        body: 'What is so interesting about interest, you may ask yourself. First of all, I will not apologize for that or any other puns. Secondly, although interest has many uses in the financial world',
                    },
                    post2 : {
                        title: 'What is an “Installment Loan”? Fig Answers!',
                        body: 'An installment loan gets its name from the payment type: you repay your loan with a number of installments, rather than paying it off all at once. The number of installment',
                    },
                    post3 : {
                        title: 'How Long Does It Take to Build Credit?',
                        body: 'The consensus from credit bureaus and lenders is that it takes at least 6 months to build up to a good credit score. While this is generally true',
                    },
                },
                decline : {
                    text : 'No pudimos obtener su aprobación previa para una oferta de préstamo, pero aquí hay otros productos para ayudarlo.'
                },
                nav : {
                    link1 : 'Empréstitos',
                    link2: 'Ahorros',
                    link3 : 'FAQ',
                    link4: 'Reseñas',
                    link5: 'Blog',
                    homeLink: 'Inicio',
                },
                aboutUs : {
                    title : 'Servicios.',
                    link1: 'Empréstitos',
                    link2: 'Ahorros',
                    link3: 'Tarjetas de crédito',
                    link4: 'Refinanciación',
                },
                contactUs: {
                    title: 'Contacta'
                },
                followUs: {
                    title: 'Síguenos'
                },
                footer : {
                    link1 : 'Divulgaciones',
                    link2 : 'Política de privacidad',
                    link3 : 'Condiciones',
                    link4 : 'No vender'
                },
                hamburger : {
                    link1 : 'Empréstitos',
                    link2 : 'Reseñas',
                    link3 : 'FAQ',
                    link4: 'Blog'
                },
                productSelector : {
                    title: 'Busco...',
                    select1: 'un préstamo personal',
                    select2: 'refinanciación',
                    select3: 'seguro de vida.',
                    select4: 'una tarjeta de crédito.',
                    select5: ' una cuenta de ahorros.',
                },
                hero : {
                    tag1 : 'Encuentra un préstamo personal',
                    tag2 : 'Encuentra una cuenta de ahorros',
                    tag3: 'Encuentra un seguro de vida',
                    tag4 : '¡Próximamente!',
                    tag5: '¡Próximamente!',
                    tagEnd : 'en tan solo 5 minutos',
                    button1: 'Verifique su tarifa',
                    button2: 'Encuentra tu cuenta',
                    button3: 'Obtener Citas',
                    button4: '¡Próximamente!',
                    button5: '¡Próximamente!',
                },
                loanCalculator : {
                    label1 : 'Cantidad',
                    label2 : 'Plazo',
                    option1: 'Año(s)',
                    option2: 'Meses',
                    label3 : 'Tasa de interés',
                    button : ' Calcular',
                    title1: 'Mensualidad',
                    title2: 'Principal pagado:',
                    title3: 'Pago interesado:',
                    education : {
                        para1: "Sin un préstamo, muchos de nosotros no podríamos comprar una casa, comprar un vehículo nuevo o seguir una educación superior. La importancia de los préstamos en la sociedad actual es enorme, ¡especialmente si se considera que la deuda promedio entre los estadounidenses es de casi $ 90,000!",
                        para2: "Desafortunadamente, la mayoría de las veces se dice que la alfabetización financiera en la educación está poco enseñada.",
                        para3: "Hay muchos factores que influyen en el cálculo preciso del costo de un préstamo. Algunos de estos incluyen el tipo de préstamo, el prestamista, su historial crediticio y créanlo o ni siquiera el mercado mismo.",
                        para4: "Buscar un préstamo puede resultar confuso y muchas veces engañoso. Es importante que como consumidor le dé prioridad a la educación antes de contraer deudas de cualquier tipo. El uso de una calculadora de préstamos puede ayudarlo a visualizar el impacto económico que puede tener un préstamo, lo que puede ahorrarle innumerables dolores de cabeza en el futuro.",
                        para5: "Nuestra calculadora deja de lado la pelusa y le ofrece una descripción clara y concisa de cómo será un préstamo fijo dadas sus circunstancias.",
                        para6: "Los préstamos pueden ser garantizados o no garantizados. Un préstamo garantizado significa que el comprador tiene que poner algún tipo de activo como garantía para garantizar el préstamo, de ahí el nombre. Un gran ejemplo de préstamo garantizado es un préstamo para automóvil. Cuando no pague un préstamo para automóvil, el prestamista cobrará su vehículo como garantía.",
                        heading1: "Consejo rapido:",
                    }
                },
                reviews : {
                    title : "Ya hemos ayudado a miles, tú eres el siguiente."
                },
                staticForm : {
                    title1: 'Encontrar un',
                    title2: 'préstamo personal.'
                },
                loanDisplay: {
                    title1: "¡Felicidades! Te han recomendado",
                    title2: ' ofertas de préstamos!'
                },
                californiaPop : {
                    text1: 'Usamos cookies en este sitio para mejorar su experiencia de usuario.',
                    text2: 'Al continuar estás aceptando nuestro ',
                    text3: 'Política de cookies',
                    text4: ' Así como nuestro ',
                    text5: 'Privacy Policy',
                },
                doNotSell : {
                    title: 'No vender mi información',
                    text1: 'The California Consumer Protection Act (CCPA) is a California regulation which protects the rights of data subjects who are residents of the state of California, with respect to the processing of their personal data as such term is defined in the CCPA.',
                    text2: 'There is no need to opt-out of the sale of your data because Fig Tech, Inc. does not sell visitor or customer data. However, if you would like to exercise your rights, please use the toggle below.',
                    text3: 'Compartir mis datos / información',
                    button1: 'Enabled',
                    button2: 'Disabled',
                },
                feedbackModal : {
                    tag : 'Preguntas?',
                    label1: 'Nombre:',
                    label2: 'Email:',
                    label3: 'Comentarios:',
                    button1: 'Submit'
                },
                // Form Copy
            loanInformation : {
                tag: 'Cuéntenos un poco sobre los requisitos de su préstamo',
                label1: 'Monto del préstamo',
                label2: 'Propósito'
            },
            creditInformation : {
                tag: "Asegurémonos de emparejarlo con la oferta más competitiva que podamos encontrar.",
                label1: 'Calificación crediticia',
            },
            creditCardInformation: {	
                tag: "¿Qué estás buscando?",	
                label1: 'Propósito de la tarjeta',	
                label2: '¿Permitir tarifa anual?',	
            },	
            savingsInformation : {	
                tag: "¿Cuánto le gustaría depositar?",	
                label1: 'Cantidad del depósito',	
            },
            name : {
                tag: '¡Queremos conocerte!',
                label1: 'Nombre',
                label2: 'Apellido'
            },
            address : {
                tag: '¡Hasta ahora tan bueno! ¿A dónde llamas hogar?',
                label1: 'Dirección 1',
                label2: 'Dirección 2',
                label3 : 'Ciudad',
                label4 : 'Código',
                label5 : 'Estado',
            },
            contact : {
                tag: '¿Cómo podemos ponernos en contacto con usted?',
                label1: 'Correo electrónico',
                label2: 'Teléfono Principal'
            },
            personalInfo : {
                tag: 'Utilizamos tecnologías de vanguardia para mantener sus datos seguros.',
                label1: 'Fecha de nacimiento',
                label2: 'SSN #',
                label3: 'Citizenship Status'
            },
            mortgageInformation : {
                tag: 'Cuéntanos sobre el lugar al que llamas hogar',
                label1: '¿Alquila o es dueño?',
            },
            financialInformation : {
                tag: '¿A qué se dedica?',
                label1: 'Estado de Empleo',
                label2: 'Horario de pago',
                label3 : 'Ingresos anuales',
                label4: 'Pago mensual de vivienda',
                label5: 'Activos disponibles'
            },
            educationInformation : {
                tag: '¡Tramo de casa! ¡Las ofertas personalizadas están a la vuelta de la esquina!',
                label1 : 'El mas alto nivel de educación',
                label2 : 'Fecha de Graduación',
                label3: 'Universidad asistida'
            },
            legalInformation : {
                tag: "Paso final, preferimos la transparencia, así que aquí está todo de una vez.",
            },
            formTitle : {
                personalLoan : 'Busque un préstamo personal.',
                studentRefi: 'Asegure su mejor tarifa.',
                creditCard: 'Encuentra la tarjeta perfecta.',	
                savingsAccount: 'Encuentre una cuenta de ahorros.'
            }
            }
            switch(language){
                case 'english':
                    return english
                case 'spanish':
                    return spanish
                default:
                    return english
            }
        }