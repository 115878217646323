import React, {useContext} from "react";
import {AppContext} from '../../../../Context/Context'
import {copy} from '../../../../Helpers/CopyText'
import FormikControl from "../FormikControl";

import { AiFillInfoCircle } from "react-icons/ai";

import ReactTooltip from "react-tooltip";

import './Styles/FormComponents.css'
import { FormGroup } from "./Styles/HelperComponents";

const incomeDisclosure = (
  <>
    <AiFillInfoCircle data-tip data-for="IncomeDisclosure" />
    <ReactTooltip
      style={{ width: "150px !important" }}
      id="IncomeDisclosure"
      type="dark"
      className="disclaimer-tooltip"
    >
      <span>
        Your yearly income before taxes, alimony, child support, or separate
        maintenance is optional and does not need to be included if you do not
        wish it to be considered as a basis for repaying the loan. Increase any
        non-taxable income by 25%.
      </span>
    </ReactTooltip>
  </>
);

const validateEmployment = (value) => {
  let error;
  if(value === ''){
    error = "Employment Status Required"
    return error;
  }
  return error;
}
const validateSchedule = (value) => {
  let error;
  if(!value) {
    error = "Pay Schedule Required"
    return error;
  }
  if(value === ''){
    error = "Pay Schedule Required"
    return error;
  }
  return error;
}
const validateIncome = (value) => {
  let error;
  if(!value) {
    error = "Annual Income Required"
    return error;
  }
  if(value === ''){
    error = "Annual Income Required"
    return error;
  }else if(value.length <= 3){
    error = "Please Enter Valid Income"
  }
  return error;
}
const validateHousingPayment = (value) => {
  let error;
  if(!value) {
    error = "Required"
    return error;
  }
  if(value === ''){
    error = "Required"
    return error;
  }
  return error;
}

function FinancialInformation(props) {

  let context = useContext(AppContext)
  let language = context.language
  let copyText = copy(language)
  
  const employmentOptions = [
    // { key: "Select One", value: "" },
    { key: "Full Time", value: "employed_full_time" },
    { key: "Part Time", value: "employed_part_time" },
    { key: "Military", value: "military" },
    { key: "Retired", value: "retired" },
    { key: "Self Employed", value: "self_employed" },
    { key: "Not Currently Employed", value: "not_employed" },
    { key: "Other", value: "other" },
  ];
  const employmentPayOptions = [
    // { key: "Select One", value: "" },
    { key: "Weekly", value: "weekly" },
    { key: "Biweekly", value: "biweekly" },
    { key: "Twice Monthly", value: "twice_monthly" },
    { key: "Monthly", value: "monthly" },
  ];

  if(props.single){
    return (
      <>
      <FormGroup>
        <FormikControl
        extraClass="single-select"
        control="select"
        options={employmentOptions}
        label={copyText.financialInformation.label1}
        name="employmentStatus"
        validate={validateEmployment}
        />
        </FormGroup>
        <FormGroup>
        <FormikControl
        extraClass="single-select"
        control="select"
        options={employmentPayOptions}
        label={copyText.financialInformation.label2}
        name="employmentPayFrequency"
        validate={validateSchedule}
        />
        </FormGroup>
      <FormGroup>
      <FormikControl
        className="single-select"
        control="input"
        type="text"
        label={copyText.financialInformation.label3}
        name="annualIncome"
        info={incomeDisclosure}
        validate={validateIncome}
      />
      </FormGroup>

      </>
    )
  }

  switch(props.product){
    case 'personal-loan':
      return (
        <>
          <FormikControl
            control="select"
            options={employmentOptions}
            label={copyText.financialInformation.label1}
            name="employmentStatus"
            validate={validateEmployment}
          />
          <FormikControl
            control="select"
            options={employmentPayOptions}
            label={copyText.financialInformation.label2}
            name="employmentPayFrequency"
            validate={validateSchedule}

          />
          <FormikControl
            control="input"
            type="text"
            label={copyText.financialInformation.label3}
            name="annualIncome"
            ph='Annual Income'
            info={incomeDisclosure}
            validate={validateIncome}
          />
        </>
      )
    case 'student-loan-refinance':
      return (
        <>
          <FormikControl
            control="select"
            options={employmentOptions}
            label={copyText.financialInformation.label1}
            name="employmentStatus"
            validate={validateEmployment}
          />
          <FormikControl
            control="select"
            options={employmentPayOptions}
            label={copyText.financialInformation.label2}
            name="employmentPayFrequency"
            validate={validateSchedule}

          />
          <FormikControl
            control="input"
            type="text"
            label={copyText.financialInformation.label3}
            name="annualIncome"
            ph='Annual Income'
            info={incomeDisclosure}
            validate={validateIncome}
          />
          <FormikControl
            control="input"
            type="text"
            label={copyText.financialInformation.label4}
            name="monthlyHousingPayment"
            ph='$1,250'
            validate={validateHousingPayment}
          />
          <FormikControl
            control="input"
            type="text"
            label={copyText.financialInformation.label5}
            name="availableAssets"
            ph="$2,500"
            validate={validateHousingPayment}
          />
        </>
      )
    default:
      return null
  };
}

export default FinancialInformation;
