import React, {useState, useContext} from "react";
import AboutUs from "./FooterComponents/AboutUs";
import ContactUs from "./FooterComponents/ContactUs.js";
import FollowUs from "./FooterComponents/FollowUs";
import { Container, FooterContainer, BottomContainer, EHOLogo, Copyright, FooterLogo, CopyrightText } from "./Styles/Footer";
import {FooterNav, FooterItem, FooterLink} from './Styles/Footer'
import eho from '../../images/equal-housing-opportunity-logo-png-transparent.png'
import logo from '../../images/logo-1.png'
import Modal from '../Modal'
import Disclosure from '../Disclosure'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {AppContext} from '../../Context/Context'

import {copy} from '../../Helpers/CopyText'

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: white;

  &:hover {
    cursor: pointer;
  }
`;

const DNSContainer = styled.div`
  width: 100%;
  background-color: #334d5d;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 1rem 0;
  font-size: .7rem;
`;

function Footer() {

  let context = useContext(AppContext)
  let language = context.language

  let copyText = copy(language)

  const [isOpen, setIsOpen] = useState(false)
  
  return (
    <>
    <FooterContainer>
    <Container>
      <AboutUs />
      <ContactUs />
      <FollowUs />
    </Container>
    </FooterContainer>
    <BottomContainer>
      <Copyright>
        <FooterLogo src={logo}/>
        <CopyrightText>Copyright &copy; 2020 Fig Loans Inc.</CopyrightText>
      </Copyright>
      <Copyright>
        <EHOLogo src={eho}/>
      </Copyright>
      <FooterNav>
        <FooterItem>
          <FooterLink onClick={() => setIsOpen(open => !open)} href="#">{copyText.footer.link1}</FooterLink>
          <Modal onClose={() => setIsOpen(false)} open={isOpen}>
            <Disclosure/>
          </Modal>
        </FooterItem>
        <FooterItem>
        <StyledLink to="/privacy-policy">{copyText.footer.link2}</StyledLink>
        </FooterItem>
        <FooterItem>
          <StyledLink to="/terms-of-service">{copyText.footer.link3}</StyledLink>
        </FooterItem>
      </FooterNav>
    </BottomContainer>
    <DNSContainer>
      <StyledLink to="/personal/opt-out">{copyText.footer.link4}</StyledLink>
    </DNSContainer>
    </>
  );
}

export default Footer;
