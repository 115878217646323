import React, {useContext} from "react";

import { FooterSection, Title, Icon, List, Item } from "../Styles/Footer";

import {AiFillFacebook} from 'react-icons/ai'

import {AiOutlineTwitter} from 'react-icons/ai'

import {copy} from '../../../Helpers/CopyText'

import {AppContext} from '../../../Context/Context'

function FollowUs() {

  let context = useContext(AppContext)
  let language = context.language

  let copyText = copy(language)

  return (
    <FooterSection>
      <Title>{copyText.followUs.title}</Title>
      <List>
        <Item>
          <Icon href="https://www.facebook.com/figloans/"><AiFillFacebook/></Icon>
        </Item>
        <Item>
          <Icon href="https://twitter.com/figloans"><AiOutlineTwitter/></Icon>
        </Item>
      </List>
    </FooterSection>
  );
}

export default FollowUs;
