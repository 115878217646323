import React from "react";
import Input from "./FormikComponents/Input";
import TextArea from "./FormikComponents/TextArea";
import Select from "./FormikComponents/Select";
import Date from "./FormikComponents/Date";
import CheckBox from "./FormikComponents/CheckBox";
import DateInput from './FormikComponents/DateInput'
import Search from "./FormikComponents/Search";

function FormikControl(props) {
  const { control, ...restProps } = props;
  switch (control) {
    case "input":
      return <Input {...restProps} />;
    case "textarea":
      return <TextArea {...restProps} />;
    case "select":
      return <Select {...restProps} />;
    case "checkbox":
      return <CheckBox {...restProps} />;
    case "date":
      return <Date {...restProps} />;
    case 'dateinput':
      return <DateInput {...restProps} />
    case 'search':
      return <Search {...restProps} />
    default:
      return null;
  }
}

export default FormikControl;
