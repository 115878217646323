import styled from 'styled-components';

export const Container = styled.div`
    width: 250px;
    background-color: #334d5d;
    box-shadow: 0 0 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    position: fixed;
    bottom: 0;
    right: 0;
    border-radius: 10px;
`;

export const FeedbackForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background-color: white;
    align-items: center;
    padding: 1rem 0;
    border-top: 4px solid #3fb39d;
    border-radius: 0 0 10px 10px;
    transition: height 100ms;
`;

export const NameInput = styled.input`
    font-size: 1rem;
  width: 80%;
  height: 1.5rem;
  border-style: none;
  color: #525252;
  text-align: center;
  background-color: #edf6f9;
  box-shadow: inset 0 0 2px;
`;

export const Comments = styled.textarea`
    font-size: 1rem;
  width: 80%;
  height: 4rem;
  border-style: none;
  color: #525252;
  text-align: center;
  background-color: #edf6f9;
  box-shadow: inset 0 0 2px;
`;

export const SubmitBtn = styled.button`
    border-style: none;
    height: 2rem;
    background-color: #3cb09b;
    min-width: 40%;
    margin-top: 1rem;
    padding: 0 1rem;
    color: white;
    border-color: #363636;
    box-shadow: 0 0 3px lightgray;
    border-radius: 5px;
    font-size: 1rem;

    &:hover {
        background-color: #334d5d;
        cursor: pointer;
    }
`;

export const Label = styled.label`
    text-align: center;
  font-size: 1rem;
  margin: 0.5rem 0;
  color: #363636;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const TagLine = styled.h3`
    text-align: center;
    color: white;
    vertical-align: middle;
    margin-right: .5rem;
    width: 20%;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    justify-content: space-between;

    &:hover {
        cursor: pointer;
    }
`;

export const Close = styled.a`
    text-decoration: none;
    color: white;

    &:hover {
        color: #334d5d;
        cursor: pointer;
    }
`;

export const TagContainer = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: white;
    width: 90%;
`;

export const ErrorMessage = styled.p`
    color: ${props => props.success ? 'green' : 'red'};
`;