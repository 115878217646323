import React, {useContext} from "react";

import {AppContext} from '../../../Context/Context'
import {copy} from '../../../Helpers/CopyText'

import {
  Form,
  FormSelect,
  FormInfo,
  FormContainer,
  FormButton,
  FormDisclaimer,
  StyledLink,
  LifeInsuranceButton,
} from "./Styles/ProductSelector";

function ProductSelector({handleChange, handleRoute, currentLinkId, handleButton, setLifeInsurance}){
  let context = useContext(AppContext)
  let language = context.language
  let copyText = copy(language)

    return (
      // These Components are Named Similar to LoanForm components but are different. Check imports.
      <FormContainer>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            console.log(e)
          }}
          onChange={handleChange}
        >
          <FormInfo>{copyText.productSelector.title}</FormInfo>
          <FormSelect value={currentLinkId}>
            <option id="1" value={1}>
            {copyText.productSelector.select1}
            </option>
            <option id="2" value={2}>
            {copyText.productSelector.select2}
            </option>
            <option id="3" value={3}>
            {copyText.productSelector.select3}
            </option>
            <option id="4" value={4}>
            {copyText.productSelector.select4}
            </option>
            <option id="" value={5}>
              {copyText.productSelector.select5}
            </option>
          </FormSelect>
          {currentLinkId === 3 ? null : <StyledLink id='herobtnlink' to={handleRoute(currentLinkId)}>
            <FormButton id='herobtn' component={StyledLink}>{handleButton(currentLinkId)}</FormButton>
          </StyledLink>}
          {
            currentLinkId === 3 ? <LifeInsuranceButton onClick={() => setLifeInsurance(true)}>{handleButton(currentLinkId)}</LifeInsuranceButton>: null
          }
          <FormDisclaimer>
            This Service will NOT affect your credit score.
          </FormDisclaimer>
        </Form>
      </FormContainer>
    );
  }

export default ProductSelector;
