import React, {useContext} from "react";
import {
  ReviewContainer,
  TrustBoxContainer,
  ReviewsHeader,
} from "./Styles/Reviews";

import TrustBox from "../Shared/TrustBox";
import {AppContext} from '../../Context/Context'
import {copy} from '../../Helpers/CopyText'


function Reviews() {

  let context = useContext(AppContext)
  let language = context.language;
  let copyText = copy(language)

  return (
    <ReviewContainer id="Reviews">
      <ReviewsHeader>{copyText.reviews.title}</ReviewsHeader>
      <TrustBoxContainer>
        <TrustBox size="big" />
      </TrustBoxContainer>
    </ReviewContainer>
  );
}

export default Reviews;
