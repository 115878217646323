import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {BsFillInfoCircleFill} from 'react-icons/bs';

export const Offer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 70%;
  background-color: white;
  padding: 1rem 1rem;
  margin-top: 1rem;
  border-radius: 10px;
  z-index: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  @media (max-width: 750px){
    flex-direction: column;
    padding: 1rem 0;
    width: 90%;
  }
`;

export const OfferDisplay = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 750px){
    flex-direction: column;
    padding: 1rem 0;
  }
`;

export const OfferImageLink = styled.a`
  width: 100%;
  @media (max-width: 750px){
      width: 60%;
      margin: auto;
  }
`;

export const OfferInfoTitle = styled.p`
  color: gray;
  width: 100%;
  text-align: center;
  margin: 0 .2rem 0 0;
  padding: 0;
  font-size: .8rem;
`;

export const OfferInfoGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    width: 100%;

    @media (max-width: 750px){
      margin: 1rem 0;
    }
`;

export const OfferLink = styled.a`
  text-decoration: none;
  width: 100%;

  color: white;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  @media (max-width:750px){
    margin: 0 auto;
    text-align: center;
  }
`;

export const OfferButton = styled.button`
  border-style: none;
  height: 2.3rem;
  background-color: #3fb39d;
  margin: 1rem 0;
  color: white;
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;

  &:hover {
    background-color: #79dcc9;
    transition: ease-in 100ms;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-width:750px){
    height: 3rem;
    font-size: 1.5rem;
    width: 50%;
  }
`;

export const OfferImage = styled.img`
  width: 100%;
`;

export const OfferInfo = styled.p`
  width: 20%;
  text-align: center;
  font-size: 1.3rem;
  margin: 0.2rem 0 0 0 ;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 750px){
    width: 100%;
    font-size: 1.5rem;
    margin: 0 0 1rem 0;
  
  }
  &:nth-child(2){
    margin: 0;
  }
`;

export const OfferTerm = styled(OfferInfo)`
  display: flex;
  flex-direction: row;

  /* &::after {
    margin: 0 0 0 0.2rem;
    content: "months";
  } */
`;

export const OfferApr = styled(OfferInfo)`

  &::after {
    content: "%";
  }
`;

export const OfferPayment = styled(OfferInfo)`
`;

export const OfferAmount = styled(OfferInfo)`
`;


export const DisclaimerText = styled.p`
  text-align: center;
  margin: 0;
  color: gray;
  text-decoration: underline;
  z-index: 10;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const MoreInfoContainer = styled.div`
  width: 95%;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: #eef7ef;
  padding: 0.2rem 0;
  border: 1px solid #e6e6e6;

  &:hover {
    cursor: pointer
  }
`;

export const MoreInfoTitle = styled.h2``;

export const MoreInfoGroup = styled.div`
  display: ${(props) => props.show ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
`;

export const MoreInfoLink = styled.p`
  margin: 0;
`;

export const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
`;

export const InfoTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.2rem;
  border: 1px solid gray;
  margin: 1rem 0 0 0 ;
  background-color: white;
`;

export const InfoTableHead = styled.thead`
  display: flex;
  flex-direction: column;
  margin: 1rem;
`;

export const InfoTableHeading = styled.th`
  color: #363636;
  margin: .5rem 0;
  padding: .5rem;

  &::after{
    content: ':'
  }
`;

export const InfoTableRow = styled.tr`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const InfoTableData = styled.td`
  margin: .5rem 0;
  background-color: #f5f5f5;
  padding: .5rem;
  text-align: center;
  color: #334d5d;
  outline: 1px solid #e6e6e6;
`;

export const InfoTableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  margin: 1rem;
`;

export const ViewMore = styled.p`
  color: #334d5d;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const DropDown = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const DropText = styled.p`
  text-align: center;
  color: gray;
  text-decoration: underline;
  margin: 0.3rem .2rem;

  &:hover {
    color: #3fb39d;
    cursor: pointer;
  }
`;

export const Banner = styled.div`
  margin: 0 1rem 0 0;
  padding: 0;
  font-weight: bold;
`;

export const Badge = styled.div`
  width: max-content;
  background-color: ${(props) => props.sub ? '' : '#e37262'};
  color: ${(props) => props.sub ? '#383838' : 'white'};;
  padding: .5rem;
  border-radius: 5px;
  margin: 0 0 .5rem 0;
  display: flex;
  align-items: center;
  box-shadow: ${(props) => props.sub ? '' : '0 0 4px'};

  @media screen and (max-width: 750px){
    margin-left: 1rem;
  }
`;

export const CurrentOfferNum = styled.p`
  width: 100%;
  color: #3d3d3d;
`;

export const BadgeDisplayContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 400px){
    flex-direction: column;
    justify-content: center;
  }

`;

export const SubDisclaimerIcon = styled(BsFillInfoCircleFill)

export const MoreInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;