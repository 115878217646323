import React from "react";
import { ErrorMessage, Field } from "formik";
import TextError from "./TextError";

function Select(props) {
  const { label, name, options, values, extraClass, setLevel, validate, ...restProps } = props;

  return (
    <div className="form-control">
      <label className="form-label" htmlFor={name}>
        {label}
      </label>
      <Field
        className={`form-select ${extraClass}`}
        id={name}
        as="select"
        name={name}
        validate={validate}
        {...restProps}
      >
          {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.key}
            </option>
          );
        })}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

export default Select;
