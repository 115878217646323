import styled from "styled-components";

export const TermsContainer = styled.div``;

export const TermNav = styled.nav``;

export const TermList = styled.ul`
  display: flex;
  flex-direction: row;
  width: 100%;
  list-style-type: none;
  padding-left: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const TermItem = styled.li`
  margin: 0 1rem;
  text-align: center;
`;

export const TermLink = styled.a``;

export const TermContainer = styled.div``;

export const TermPolicy = styled.p``;

export const TermTitle = styled.h3``;
