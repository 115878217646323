import styled from "styled-components";

import { FaThumbsUp } from "react-icons/fa";

export const ModalOverlay = styled.div`
  position: absolute;
  margin-top: 20vh;
  background-color: ${({ nodisplay }) =>
    nodisplay ? "" : "rgba(0, 0, 0, 0.172)"};
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
`;

export const ModalContainer = styled.div`
  background-color: white;
  padding: 2rem;
  z-index: 1000;
  box-shadow: 0 0 7px gray;
  border-radius: 10px;
  width: 50%;
  margin: 0 auto;

  @media screen and (max-width: 760px) {
    width: 80%;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ModalIcon = styled(FaThumbsUp)`
  color: #334d5d;
  font-size: 8rem;
`;

export const ModalText = styled.p`
  font-size: 2rem;
  text-align: center;
`;

export const ModalButton = styled.button`
  border-style: none;
  background-color: #334d5d;
  color: white;
  padding: 1rem 2rem;
  font-size: 2rem;
  border-radius: 10px;
  width: 100%;

  &:hover {
    cursor: pointer;
    background-color: #3fb39d;
  }
`;
