import React, {useState} from 'react'

import {Container, Form, Label, Input, Button, TextArea, Title, HomeText} from './Styles/ContactForm'

import {sendFeedback} from '../../../Helpers/Feedback'

import {Link} from 'react-router-dom'

function ContactForm() {

    const [submitted, setSubmitted] = useState(false);
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [body, setBody] = useState('')

    const [errorMessage, setErrorMessage] = useState('')

    const handleSubmit = (name, email, comments) => {
        if(name.length < 1){
            setErrorMessage('Name Required')
        }else if(email.length < 1){
            setErrorMessage('Email Required')
        }else if(comments.length < 1){
            setErrorMessage('Comments Required')
        }else{
            setErrorMessage('')
            sendFeedback(name, email, comments)
            setSubmitted(true)
        }
    }

    if(submitted){
        return (
            <Container>
                <Title>Thank you for your feedback!</Title>
                <HomeText>Click <Link to="/">here</Link> to return to the Marketplace home page.</HomeText>
                <Link to={"/"}><Button>Click Here</Button></Link>
            </Container>
        )
    }

    return (
        <Container>
            <Form method="post" name="ErrorForm" enctype="text/plain">
                <Label htmlFor="name">Name:</Label>
                <Input required onChange={e => setName(e.target.value)} id="name" name="name"></Input>
                <Label htmlFor="email">Email:</Label>
                <Input required onChange={e => setEmail(e.target.value)} id="email" name="email"></Input>
                <Label htmlFor="issue">Issue:</Label>
                <TextArea required onChange={e => setBody(e.target.value)} id="issue" name="issue"></TextArea>
                {errorMessage && errorMessage.length > 1 ? <p style={{color: 'red'}}>{errorMessage}</p> : null}
                <Button onClick={() => handleSubmit(name, email, body)} type="submit">Submit</Button>
            </Form>
        </Container>
    )
}

export default ContactForm
