import React, {useContext} from "react";
import {AppContext} from '../../../../Context/Context'
import {copy} from '../../../../Helpers/CopyText'
import FormikControl from "../FormikControl";
import { FormGroup } from "./Styles/HelperComponents";

export const statusOptions = [
  // { key: "Select One", value: "" },
  { key: "Excellent (720 +)", value: "excellent" },
  { key: "Good (660-719)", value: "good" },
  { key: "Fair (620-659)", value: "fair" },
  { key: "Poor (< 620)", value: "poor" },
  { key: "Unknown", value: "limited" },
];

const validateCreditRating = (value) => {
  let error;
  if(value === ""){
    error = 'Please Provide your Credit Rating'
  }
  return error;
}

function CreditInformation(props) {

  let context = useContext(AppContext)
  let language = context.language
  let copyText = copy(language)

  if(props.single){
    return (
      <FormGroup>
        <FormikControl
        extraClass="single-select"
        control="select"
        options={statusOptions}
        label={copyText.creditInformation.label1}
        name="providedCreditRating"
        validate={validateCreditRating}
      />
      </FormGroup>
    )
  }

  return (
    <>
      <FormikControl
        control="select"
        options={statusOptions}
        label={copyText.creditInformation.label1}
        name="providedCreditRating"
        validate={validateCreditRating}
      />
    </>
  );
}

export default CreditInformation;
