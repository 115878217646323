import styled from "styled-components";

import { CgArrowRightR } from "react-icons/cg";

export const FlowContainer = styled.section`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 2rem auto;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  @media (min-width: 2000px){
    max-width: 1000px;
  }
`;

export const FlowImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fdfdfd;
  min-height: 20vh;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem 2rem;
  border-radius: 10px;
  /* border: 1px solid #e6e5e5;
  box-shadow: 0 1px 1px rgba(0,0,0,0.11), 
              0 2px 2px rgba(0,0,0,0.11), 
              0 4px 4px rgba(0,0,0,0.11), 
              0 6px 8px rgba(0,0,0,0.11),
              0 8px 16px rgba(0,0,0,0.11); */

  &:hover {
    cursor: pointer;
    background-color: #eef7ef;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 0;
    max-width: 50%;
  }
`;

export const FlowImage = styled.img`
  margin: 0 auto;
  height: 40%;
  width: 40%;

  @media (max-width: 800px) {
    width: 30%;
  }
`;

export const FlowDesc = styled.p`
  text-align: center;
  font-size: 1.3rem;
  height: 40%;

  @media (max-width: 800px) {
    width: 90%;
    margin: 1rem auto;
  }
`;

export const Arrow = styled(CgArrowRightR)`
  margin: 0 2rem;
  color: #3fb39d;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const FlowHeader = styled.h2`
  font-size: 2rem;
  text-align: center;
  margin-top: 5%;
  color: #334d5d;
`;
