import styled from "styled-components";

import {Link as ScrollLink} from 'react-scroll'

export const HeaderContainer = styled.header`
  width: 80%;
  display: flex;
  margin: 0 auto;
  flex-direction: row;


  @media (max-width: 600px) {
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

export const BurgerContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const LogoContainer = styled.div`
  width: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  @media (max-width: 950px) {
    width: 100%;
  }
`;

export const NavContainer = styled.nav`
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 950px) {
    display: none;
  }
`;

export const NavList = styled.ul`
  display: ${props => props.hide ? 'none' : 'flex'};
  flex-direction: row;
  list-style-type: none;
  padding-left: 0;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 450px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const NavItem = styled.li`
  margin: 0 1rem;
  text-transform: uppercase;

`;

export const LogoImg = styled.img`
  height: 4rem;
  margin: 1rem 0.5rem;

`;

export const Logo = styled.h2`
  padding: 0;
  margin: 0;
  @media (max-width: 350px) {
    display: none;
  }
`;

export const NavLink = styled(ScrollLink)`
text-decoration: none;
  color: #334d5d;
  font-size: 1.2rem;

  &:hover {
    color: #3fb39d;
    cursor: pointer;
  }
`;

export const LanguageContainer = styled.div`
  right: 0;
  background-color: #3fb39d;
  color: white;
  border-radius: 10px;
  padding: .5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 10rem;
  box-shadow: 0 0 4px gray;

`;

export const Language = styled.a`
  margin-right: 1rem;
  color: white;
  
  &:hover {
    cursor: pointer;
    color: #334d5d;
  }
`;