export const sendFeedback = (name, email, body) => {
    let header = {
        "Content-Type": "application/json",
        Accept: "application/json",
    }
    let postBody = {
        name : name,
        email : email,
        body: body,
    }

    fetch('https://app.figloans.com/api/leads/inquiry', {
        method: 'POST',
        headers: header,
        body: JSON.stringify(postBody)
    })
}

export const sendErrorReport = ({error, location}) => {
    let header = {
        "Content-Type": "application/json",
        Accept: "application/json",
    }
    let postBody = {
        error,
        location,
    }

    fetch('https://app.figloans.com/api/leads/error', {
        method: 'POST',
        headers: header,
        body: JSON.stringify(postBody)
    })
}