import styled from 'styled-components';

export const Table = styled.table`
    width: 100%;
    font-size: 1.2rem;
    border: 1px solid gray;
    margin: 1rem 0 0 0 ;
    background-color: white;
    padding: 1rem;
    z-index: 9999;

    @media screen and (max-width: 730px){
        width: 100%;
    }
`;

export const Head = styled.thead`
    text-align: center;
    border: 1px solid black !important;
`;

export const Body = styled.tbody`
    text-align: center;
`;

export const Row = styled.tr`
    width: 100%;
    border: 1px solid black;
`;

export const Data = styled.td`
`;

export const Heading = styled.td``;

export const Link = styled.a``;

export const Button = styled.button`
    border-style: none;
    height: 2.3rem;
    background-color: #3fb39d;
    color: white;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;

    &:hover {
        background-color: #79dcc9;
        transition: ease-in 100ms;
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }
`;
