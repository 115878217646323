import { formatInput } from "./Formatting";

export const SaveData = (name, values) => {
    if(localStorage.getItem('dns') === 'true'){
        let data = JSON.stringify(values)
        localStorage.setItem(name, data)
    }
}

export const LoadData = (product) => {
    let load;
    if(localStorage.getItem(product) === null){
        if(product === 'personal-loan'){
          load = initialLoanApp
        }
        else if(product === 'student-loan-refinance'){
          load = initialStudentLoanRefinance
        }else if(product === 'credit-card'){
          load = initialCreditCard
        }else if(product === 'savings-account'){
          load = initialSavingsAccount
        }
    }else{
        load = JSON.parse(localStorage.getItem(product))
    }
    return load;
}

export const getInitialValues = (data, ip, source) => {

    let queryString = (window.location.search)

    const urlParams = new URLSearchParams(queryString);

    const entries = urlParams.entries();

    for(const entry of entries) {
      let key = entry[0];
      let value = entry[1];
      if(key === 'primaryPhone' || key === 'ssn' || key === 'dateOfBirth' || key === 'annualIncome' || key === 'loanAmount'){
        data[key] = formatInput(value, key)
      }else if(data[key] === '' || data[key]){
        data[key] = value;
      }
    }
    data['ipAddress'] = ip;
    if(source){
      data['source'] = [source];
    }else {
      data['source'] = ['organic']
    }
    return data;
  }

  export const initialLoanApp = {
    productType: "loan",
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "NY",
    zipcode: "",
    email: "",
    primaryPhone: "",
    dateOfBirth: "",
    ssn: "",
    purpose: "debt_consolidation",
    loanAmount: "$5,000",
    propertyStatus: "rent",
    providedCreditRating: "good",
    employmentStatus: "employed_full_time",
    employmentPayFrequency: "weekly",
    annualIncome: "",
    educationLevel: "high_school",
    graduationDate: "",
    consentsToTcpa: false,
    consentsToFcra: true,
    ipAddress: '',
    source: '',
    tcpaLanguage:
      "I agree to be contacted by Engine by MoneyLion and its partners at the telephone number(s) I have provided above to explore personal loan offers, including contact through automatic dialing systems, artificial or pre-recorded voice messaging, or text message. I understand my consent is not required as a condition to purchasing any goods or services from anyone.",
    fcraLanguage:
      "In connection with your application for credit, partners may obtain and use an 'investigative consumer report' about you from a 'consumer reporting agency.'"
  };

  export const initialStudentLoanRefinance = {
    productType: "loan",
    productSubType: "student_loan_refinance",
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "NY",
    zipcode: "",
    email: "",
    primaryPhone: "",
    dateOfBirth: "",
    ssn: "",
    purpose: "student_loan_refi",
    loanAmount: "$10,000",
    propertyStatus: "rent",
    providedCreditRating: "good",
    employmentStatus: "employed_full_time",
    employmentPayFrequency: "weekly",
    annualIncome: "",
    monthlyHousingPayment : '',
    availableAssets : '',
    educationLevel: "high_school",
    universityAttended : "",
    graduateDegreeType : 'other',
    graduationDate: "",
    citizenshipStatus : "citizen",
    consentsToTcpa: false,
    consentsToFcra: true,
    ipAddress: "",
    source: '',
    tcpaLanguage:
      "I agree to be contacted by Engine by MoneyLion and its partners at the telephone number(s) I have provided above to explore personal loan offers, including contact through automatic dialing systems, artificial or pre-recorded voice messaging, or text message. I understand my consent is not required as a condition to purchasing any goods or services from anyone.",
  };

  export const initialCreditCard = {
    productType: "credit_card",
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "NY",
    zipcode: "",
    email: "",
    dateOfBirth: "",
    providedCreditRating: "good",
    cardPurposes: [],
    allowAnnualFee: true,
    consentsToTcpa: false,
    consentsToFcra: true,
    ipAddress: '',
    source: '',
    tcpaLanguage:
      "I agree to be contacted by Engine by MoneyLion and its partners at the telephone number(s) I have provided above to explore personal loan offers, including contact through automatic dialing systems, artificial or pre-recorded voice messaging, or text message. I understand my consent is not required as a condition to purchasing any goods or services from anyone.",
    fcraLanguage:
      "In connection with your application for credit, partners may obtain and use an 'investigative consumer report' about you from a 'consumer reporting agency.'"
  };

  export const initialSavingsAccount = {
    productType: "savings",
    zipcode: '',
    providedCreditRating: '',
    minDepositAmount: '1000',
    source: '',
  };
