import styled from "styled-components";

export const FaqContainer = styled.div`
  width: 80%;
  margin: 2rem auto;
  text-align: left;
  @media (max-width: 640px){
    width: 90%;
  }
`;

export const FaqHeader = styled.h2`
  font-size: 2rem;
  text-align: center;
  color: #334d5d;
  margin-top: 3rem;
  @media (max-width: 420px){
    font-size: 1.6rem;
  }
`;

export const FaqCTA = styled.h3`
  color: #3fb39d;
  font-size: 1.5rem;
  text-align: center;
`;

export const FaqButton = styled.button`
  border-style: none;
  height: 3rem;
  background-color: #e37262;
  min-width: 10%;
  margin: 1rem 0;
  color: white;
  border-radius: 5px;
  font-size: 1rem;
  padding: 0.5rem 3rem;

  &:hover {
    background-color: #cc503f;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

export const CTAContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  margin-bottom: 2rem;
`;