import React from "react";
import { ErrorMessage } from "formik";
import TextError from "./TextError";

import { Field } from "formik";

import styled from "styled-components";

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: ${(props) => (props.checked ? "#3fb39d" : "#edf6f9")};
  border-radius: 3px;
  border: 1px solid #334d5d;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px #334d5d;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 1rem;
`;

const Checkbox = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

function CheckBox(props) {
  const { label, name, value, validate, id, ...restProps } = props;
  return (
    <div className="form-control checkbox">
      <label
        style={{ maxWidth: "40%", marginLeft: "auto", marginRight: "auto" }}
        className="legal-label"
        htmlFor={name}
      >
        <Field {...restProps} name={name} validate={validate}>
          {({ field, form }) => {
            return <Checkbox id={name} checked={field.value} {...field} />;
          }}
        </Field>
        {label}
      </label>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

export default CheckBox;
