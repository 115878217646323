import styled from 'styled-components'
import {BsFillShieldLockFill} from 'react-icons/bs'

import {Link} from 'react-router-dom'

export const Container = styled.div`
    display: flex;
    /* display: flex; */ 
    visibility: ${props => props.open ? '' : 'hidden'};
    flex-direction: row;
    position: fixed;
    bottom: 0;
    background-color: #3fb39d;
    width: 100%;
    padding: 1rem 0;
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.8);
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
`;

export const CcpaText = styled.p`
    color: white;
    font-size: 1rem;

`;

export const Close = styled.a`
    color: white;
    font-size: 1.6rem;

    &:hover {
        color: #334d5d;
        cursor: pointer;
    }
`;

export const CcpaLink = styled(Link)`
    text-decoration: underline;
    color: #eef7ef;
`;

export const Icon = styled(BsFillShieldLockFill)`
    color: white;
    font-size: 3rem;
`;