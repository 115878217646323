import React from 'react'

import { Table, Head, Body, Row, Data, Heading, Link, Button} from './Styles/OfferBox'


import {formatInput} from '../../../../Helpers/Formatting'

function OfferBox({offers}) {
    return (
                    <Table>
                        <Head>
                            <Row>
                                <Heading>Term</Heading>
                                <Heading>Amount</Heading>
                                <Heading>Payment</Heading>
                            </Row>
                        </Head>
                        <Body>
                            {
                                offers.map((offer, i) => {
                                    return (
                                        <Row key={i}>
                                            <Data>{offer.termLength}</Data>
                                            <Data>{formatInput(offer.maxAmount.toString(), 'loanAmount')}</Data>
                                            <Data>${offer.meanMonthlyPayment ? offer.meanMonthlyPayment.toFixed(2): ''}</Data>
                                            <Data><Link target="_blank" href={offer.url}><Button>Continue</Button></Link></Data>
                                        </Row>
                                    )
                                })
                            }
                        </Body>
                    </Table>
    )
}

export default OfferBox
