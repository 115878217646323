import styled from "styled-components";
import image from '../../../images/header-pattern.png'

export const ReviewContainer = styled.section`
  width: 100%;
  background-color: #eef7ef;
  box-shadow: inset 0 0 6px gray;
  background: url(${image});
  background-attachment: fixed;
`;

export const TrustBoxContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  padding-bottom: 3rem;
`;

export const ReviewsHeader = styled.h2`
  font-size: 2rem;
  text-align: center;
  padding: 1rem 0 0 0;
  color: #334d5d;
`;
