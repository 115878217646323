import styled from 'styled-components'

export const DisplayContainer = styled.section`
    width: 100%;
`;


export const DisplayHeader = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #3fb39d;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 2px -2px #aaaaaa;
`;
export const HeaderTitle = styled.h2`
    color: white;
    font-size: 2rem;
    margin-bottom: 0;
`;
export const HeaderTag = styled.p`
    color: white;
    font-size: 1.5rem;
`;


export const DisplayForm = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 70%;
    margin: 1rem auto;
    border-radius: 10px;
`;
export const Form = styled.form`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    
    @media screen and (max-width: 1290px){
        flex-direction: column;
        align-items: center;
    }
`;
export const FormLabel = styled.label`
    display: flex;
    flex-direction: column;
    width: 20%;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 1290px){
        width: 100%;
        margin: 1rem auto 0 auto;
        justify-content: center;
    }
`;
export const FormInput = styled.input`
    height: 2.3rem;
    padding: 0 0 0 .5rem;
    font-size: 1rem;
    margin-top: 1rem;
    border: 1px solid gray;
    border-radius: 5px;
    border-color: lightgray;
    background-color: #f0faf8;
    width: 80%;
    border-style:solid;
    @media screen and (max-width: 1290px){
        margin-left: 1rem;
        width: 50%;
    }
`;
export const FormSelect = styled.select`
    height: 2.5rem;
    padding: 0 0 0 .5rem;
    margin-top: 1rem;
    font-size: 1rem;
    border-radius: 5px;
    background-color: #f3fcfa;
    border-color: lightgray;
    width: 80%;
    @media screen and (max-width: 1290px){
        margin-left: 1rem;
        width: 50%;
    }
`;
export const FormButton = styled.button`
    border-style: none;
    height: 2.3rem;
    background-color: #3fb39d;
    color: white;
    border-radius: 5px;
    font-size: 1rem;
    width: 20%;

    &:hover {
        background-color: #79dcc9;
        transition: ease-in 100ms;
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }

    @media screen and (max-width: 1290px){
        margin-top: 1rem;
    }
    @media screen and (max-width:750px){
        height: 3rem;
        font-size: 1.5rem;
        width: 50%;
    }
    
`;

export const FormButtonLink = styled.a`
    text-decoration: none;
    color: white;
    background-color:#3fb39d;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 2.3rem;

    &:hover {
        background-color: #79dcc9;
        transition: ease-in 100ms;
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }

    @media screen and (max-width: 1290px){
        margin-top: 1rem;
    }
    @media screen and (max-width:750px){
        height: 3rem;
        font-size: 1.5rem;
        width: 50%;
    }
`;


export const DisplaySort = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    form {
        display: flex;
        width: 100%;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
    }
`;


export const DisplayOffers = styled.div`
`;


export const EffectiveContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    margin: 0 auto;
    align-items: center;

    @media screen and (max-width: 250px){
        flex-direction: column;
    }
`;
export const EffectiveDate = styled.p`
    color: gray;
    font-style: italic;
`;
export const ProductType = styled.p`
    color: gray;
    font-style: italic;
`;


export const Offer = styled.div`
display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 70%;
  background-color: white;
  padding: 1rem 1rem;
  margin: 1rem auto 0 auto;
  border-radius: 10px;
  z-index: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  @media (max-width: 750px){
    flex-direction: column;
    padding: 1rem 0;
    width: 90%;
  }
`;
export const Display = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (max-width: 1290px){
    flex-direction: column;
    }
`;
export const OfferImage = styled.img`
    max-width: 20%;
    @media (max-width: 1290px){
        max-width: 30%;
    }
    @media screen and (max-width: 725px){
        max-width: 70%;
    }
`;
export const OfferAPY = styled.div`
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
`;
export const APY = styled.p`
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 0;
    margin-bottom: 0;
    @media (max-width: 1290px){
        font-size: 2.5rem;
    }
`;
export const OfferButton = styled.button`
  border-style: none;
  height: 2.3rem;
  background-color: #3fb39d;
  margin: 1rem 0;
  color: white;
  border-radius: 5px;
  font-size: 1rem;
  width: 20%;

  &:hover {
    background-color: #79dcc9;
    transition: ease-in 100ms;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-width:1290px){
    height: 3rem;
    font-size: 1.5rem;
    width: 40%;
    margin-top: 2rem;
  }

`;
export const DisclaimerContainer = styled.div`
    @media screen and (min-width: 1291px){
        width: 100%;
    }
`;


export const OfferInfo = styled.div`
    display: flex;
    flex-direction: row;
    width: 40%;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1290px){
        width: 80%;
        justify-content: space-between
    }
    @media screen and (max-width: 985px){
        flex-direction: column;
    }
`;
export const InfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2% 0 2%;
    width: 100%;
`;
export const InfoItem = styled.p`
    margin-bottom: 0;

    @media screen and (max-width: 1290px){
        text-align: center;
        border: 1px solid gray;
        padding: 1rem;
        border-radius: 5px;
        width: 80%;
    }
`;

export const RateTableFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 70%;
    margin: 1rem auto;
    `;


export const DisclosureOverlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position:fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    height:100%;
    width:100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;

export const DisclosureContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0 auto;
    background-color: white;
    min-height: 50vh;
    padding: 1.5rem;
    border-radius: 8px;
`;

export const DisclosureText = styled.p`
    font-weight: ${props => props.title ? 'bold' : ''}
`;

export const DisclosureLink = styled.p`
    color: #334d5d;
    text-decoration: underline;

    &:hover{
        color: #3fb39d;
        cursor: pointer;
    }
`;


export const LoadingContainer = styled.div``;