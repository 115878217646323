import styled from "styled-components";

export const ScenarioHeader = styled.h2`
  font-size: ${props => props.mobile ? '1.5rem' : '2rem'};
  text-align: center;
  color: #334d5d;
  width: 80%;
  margin: 2rem auto 2rem auto;
`;

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const ScenarioContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  @media (max-width: 960px){
    flex-direction: column;
  }
`;

export const Scenario = styled.img`
  width: 300px;
  height: 300px;
  margin: 0.5rem;
  box-shadow: 0 0 3px;

  @media (max-width: 1280px){
    width: 225px;
    height: 225px;
  }

  @media (max-width: 960px) {
    width: 400px;
    height: 400px;
  }

  @media (max-width: 450px) {
    width: 250px;
    height: 250px;
  }

`;

export const Button = styled.button`
  border-style: none;
  height: 3rem;
  background-color: #e37262;
  width: 100%;
  margin: 1rem 0;
  color: white;
  border-radius: 5px;
  font-size: 1.3rem;
  padding: 0.5rem 3rem;

  &:hover {
    background-color: #cc503f;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;
