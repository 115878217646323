import React from "react";
import { QuestionContainer, Question, Answer } from "./Styles/FaqSection";

import {BsChevronDown, BsChevronUp} from 'react-icons/bs'

import {CSSTransition} from 'react-transition-group'

import './Styles/FaqSection.css'

const FaqSection = ({active, question, answer, index, toggleAnswer}) => {
  return (
      <QuestionContainer onClick={() => toggleAnswer(index)}>
        <Question>{question}{active ? <BsChevronUp className="faq-icon up"/> : <BsChevronDown className="faq-icon down" />}</Question>
          {active ? 
              <Answer>
                {answer}
              </Answer>
            : null}
      </QuestionContainer>
    )
}

export default FaqSection;
