import React, { useState } from "react";

import {
  ModalContainer,
  ModalOverlay,
  ModalContent,
  ModalText,
  ModalButton,
  ModalIcon,
} from "./Styles/TransitionModal";

function TransitionModal({ source }) {
  const [display, setDisplay] = useState(true);

  const toggleDisplay = () => {
    if (display) {
      setDisplay(false);
    }
  };

  const handleText = (source) => {
    console.log(source);
    switch (source) {
      case "bank_account":
        return "Unfortunately, we are unable to work with your bank. Let's try pairing you with one of our trusted partners.";
      case "illinois":
        return "Oh no! It looks like you aren't eligible right now for a short term loan in Illinois. Let's try pairing you with one of our trusted partners!";
      case "florida":
        return "Oh no! It looks like you aren't eligible right now for a short term loan in Florida. Let's try pairing you with one of our trusted partners!";
      default:
        return "We are almost there! Just a little further...";
    }
  };

  if (display) {
    return (
      <ModalOverlay nodisplay onClick={() => toggleDisplay()}>
        <ModalContainer>
          <ModalContent>
            <ModalIcon />
            <ModalText>{handleText(localStorage.getItem("source"))}</ModalText>
            <ModalButton type="button" onClick={() => toggleDisplay()}>
              Continue
            </ModalButton>
          </ModalContent>
        </ModalContainer>
      </ModalOverlay>
    );
  } else {
    return null;
  }
}

export default TransitionModal;
