import React, {useContext} from "react";

import { FooterSection, Title, List, Item } from "../Styles/Footer";

import {AppContext} from '../../../Context/Context'

import {copy} from '../../../Helpers/CopyText'

import {Link as RouteLink} from 'react-router-dom'

function AboutUs() {
  let context = useContext(AppContext)
  let language = context.language

  let copyText = copy(language)

  return (
    <FooterSection>
      <Title>{copyText.aboutUs.title}</Title>
      <List>
        <Item>
          <RouteLink to="/personal-loan/application/0">{copyText.aboutUs.link1}</RouteLink>
        </Item>
        <Item>
          <RouteLink to="/savings-account">{copyText.aboutUs.link2}</RouteLink>
        </Item>
        {/* <Item>
          <Link>{copyText.aboutUs.link3}</Link>
        </Item>
        <Item>
          <Link>{copyText.aboutUs.link4}</Link>
        </Item> */}
      </List>
    </FooterSection>
  );
}

export default AboutUs;
