import React from "react";

import "./Delay.css";

class Delayed extends React.Component {
  state = { hidden: false };

  componentDidMount() {
    if(this.props.waitBeforeShow){
      this.setState({hidden:true})
      setTimeout(() => {
        this.setState({ hidden: false });
      }, this.props.waitBeforeShow);
    }
  }

  render() {
    return this.state.hidden || this.props.loading ? (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    ) : (
      this.props.children
    );
  }
}

export default Delayed;
