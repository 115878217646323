import styled from "styled-components";
import image from '../../../images/header-pattern.png'

export const Container = styled.footer`
  width: 100%;
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background: url(${image});
  background-attachment: fixed;
  box-shadow: inset 0 6px 6px -6px gray, 
              inset 0 -6px 6px -6px gray;


  @media (max-width: 600px){
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const FooterSection = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px){
    width: 50%;
  }
`;

export const Title = styled.h2`
  color: #334d5d;
  margin-bottom: 0;
  text-align: center;
`;

export const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;

  @media (max-width: 600px){
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 0;
   margin: 0; 
  }
`;

export const Item = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2rem;
  margin-top: .5rem;
  margin-left: .5rem;

  @media (max-width: 600px){
    margin-left: 0; 
  }
  
`;

export const Link = styled.a`
  text-decoration: none;
  color: #334d5d;

  &:hover {
    color: #3fb39d;
    cursor: pointer;
  }
`;

export const Info = styled.p`
  color: white;
`;

export const EHOLogo = styled.img`
  width: 15%;

  @media(min-width: 2000px){
    width: 10%;
  }

  @media(max-width: 1100px){
    width: 20%;
  }

  @media(max-width: 700px){
    width: 25%;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #334d5d;
  max-width: 100%;
  padding: 1rem 2rem .5rem 2rem;
`;

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #3fb39d;
  justify-content: center;
  align-items: center;
  background: url(${image});
  background-attachment: fixed;
  box-shadow: inset 0 0 6px gray;
`;

export const FooterLogo = styled.img`
  width: 10%;
  margin-right: 2rem;
  color: white;

  @media (max-width: 1000px){
   width: 20%;
  }
`;

export const Copyright = styled.div`
  display: flex;
  flex-direction: row;
  width: 33%;
  justify-content: center;
  align-items: center;
`;

export const CopyrightText = styled.p`
  color: white;
`;

export const FooterNav = styled.ul`
  list-style-type: none;
  padding-left: 0;
  width: 33%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const FooterItem = styled.li`
  margin: 0 .5rem;
`;

export const FooterLink = styled.a`
  color: white;
`;


export const Icon = styled.a`
  color: #334d5d;
  font-size: 2rem;
  margin-top: 1rem;

  &:hover {
    color: #3fb39d;
  }
`;