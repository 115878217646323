import React, { Component } from "react";

const TrustBox = ({ trustBoxRef, size, listFiltered }) => {
  // return <div />;
  let template, height;
  switch (size) {
    case "big":
      template = "539adbd6dec7e10e686debee";
      height = "500px";
      break;
    case "medium":
      template = "539adbd6dec7e10e686debee";
      height = "400px";
      break;
    case "small":
      template = "5419b6ffb0d04a076446a9af";
      height = "20px";
      break;
    default:
      template = "539adbd6dec7e10e686debee";
      height = "500px";
      break;
  }
  return listFiltered ? (
    <div
      class="trustpilot-widget"
      data-locale="en-US"
      data-template-id="539ad998dec7e10e686debe0"
      data-businessunit-id="59f354920000ff0005aed321"
      data-style-height="500px"
      data-style-width="100%"
      data-theme="light"
      data-review-languages="en"
      ref={trustBoxRef}
    >
      <a
        href="https://www.trustpilot.com/review/www.figloans.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  ) : (
    <div
      className={`trustpilot-widget${size}`}
      data-locale="en-US"
      data-template-id={template}
      data-businessunit-id="59f354920000ff0005aed321"
      data-style-height={height}
      data-style-width="100%"
      data-theme="light"
      data-stars="5"
      ref={trustBoxRef}
    >
      {" "}
      <a
        href="https://www.trustpilot.com/review/www.figloans.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>{" "}
    </div>
  );
};
class TrustBoxContainer extends Component {
  constructor(props) {
    super(props);
    this.trustBoxRef = React.createRef();
  }
  componentDidMount() {
    if (!window.Trustpilot) {
      const script = document.createElement("script");
      script.src =
        "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
      script.onload = () =>
        window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
      document.body.appendChild(script);
    }

    // debugger;
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
    }
  }
  render() {
    return <TrustBox trustBoxRef={this.trustBoxRef} {...this.props} />;
  }
}
export default TrustBoxContainer;
