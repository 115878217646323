import React, { Component } from 'react';

import ContactForm from './ContactForm'

import {Container, Logo, Title, Text} from './Styles/ErrorBoundary';

import image from '../../images/logo-1.png';

import {sendErrorReport} from '../../Helpers/Feedback'
import Header from '../Header';
import Footer from '../Footer';


class ErrorBoundary extends Component {

    state = {
        hasError : false
    }

    static getDerivedStateFromError(error){
        sendErrorReport(error.stack, 'Fig Marketplace');
        return {
            hasError : true
        }
    }

    render() {
        if(this.props.force){
            return (
                    <Container>
                        <Logo src={image}/>
                        <Title important>We're sorry,</Title>
                        <Title>either this page doesn't exist or you've encountered an error</Title>
                        <Text>Please let us know what happened so we can get to work fixing it,</Text>
                        <ContactForm />
                    </Container>
            )
        }
        if(this.state.hasError){
            return (
                <>
                    <Header/>
                        <Container>
                            <Logo src={image}/>
                            <Title important>We're sorry,</Title>
                            <Title>either this page doesn't exist or you've encountered an error</Title>
                            <Text>Please let us know what happened so we can get to work fixing it,</Text>
                            <ContactForm />
                        </Container>
                    <Footer/>
                </>
            )
        }
        return this.props.children
    }
}

export default ErrorBoundary;
