import React, {useState, useEffect, useContext} from 'react'

import {Container, Content, CcpaText, CcpaLink, Close, Icon} from './Styles/CaliforniaPop'

import {AppContext} from '../../../Context/Context'
import {copy} from '../../../Helpers/CopyText'


function CaliforniaPop() {

    let context = useContext(AppContext)
    let language = context.language;
    let copyText = copy(language)

    const [popOpen, setPopOpen] = useState(false)

    const handleClose = () => {
        setPopOpen(false)
        localStorage.setItem('washere', true)
    }

    const checkSession = () => {
        const hasVisited = localStorage.getItem('washere');

        if(!hasVisited){
            setPopOpen(true)
            setTimeout(function(){
                setPopOpen(false)
                localStorage.setItem('washere', true)
            }, 15000);
        }
    }

    useEffect(() => checkSession())

    return (
        <Container open={popOpen}>
            <Content>
                <Icon/>
                <CcpaText>{copyText.californiaPop.text1}<br/>{copyText.californiaPop.text2}<CcpaLink to="/privacy-policy">{copyText.californiaPop.text3}</CcpaLink>{copyText.californiaPop.text4}<CcpaLink to="/privacy-policy">{copyText.californiaPop.text5}</CcpaLink></CcpaText>
                <Close onClick={() => handleClose()}>X</Close>
            </Content>
        </Container>
    )
}

export default CaliforniaPop
