import React, {useContext} from "react";
import {AppContext} from '../../../../Context/Context'
import {copy} from '../../../../Helpers/CopyText'
import FormikControl from "../FormikControl";
import { FormGroup } from "./Styles/HelperComponents";

export const purposeOptions = [
  // { key: "Select One", value: "" },
  { key: "Debt Consolidation", value: "debt_consolidation" },
  { key: "Credit Card Refinance", value: "credit_card_refinance" },
  { key: "Home Improvement", value: "home_improvement" },
  { key: "Large Purchase", value: "large_purchase" },
  { key: "Student Expenses", value: "student" },
  { key: "Car Expenses", value: "auto" },
  { key: "Baby Expenses", value: "baby" },
  { key: "Wedding Expenses", value: "wedding" },
  { key: "Boat Expenses", value: "boat" },
  { key: "Business", value: "business" },
  { key: "Medical/Dental", value: "medical" },
  { key: "Taxes", value: "taxes" },
  { key: "Vacation", value: "vacation" },
];

const validateLoanAmount = (value) => {
  let error;
  let newValue = value.replace(/\D/g, "")
  if(newValue < 500){
    error = "Loan Request Too Low"
  }else if(newValue > 100000){
    error = 'Loan Request Too High'
  }else if(value === ''){
    error = "Loan Amount Required"
  }
  return error;
}

const validateLoanPurpose = (value) => {
  let error;
  if(value === ''){
    error = "Loan Purpose Required!"
  }
  return error;
}


function LoanInformation(props) {

  let context = useContext(AppContext)
  let language = context.language
  let copyText = copy(language)

  if(props.single){
    return (
      <FormGroup>
         <FormikControl
            control="input"
            label={copyText.loanInformation.label1}
            name="loanAmount"
            ph="$500-$100,000"
            validate={validateLoanAmount}
            max={20000}
            min={500}
            maxLength={8}
          />
          <FormikControl
            control="select"
            options={purposeOptions}
            label={copyText.loanInformation.label2}
            name="purpose"
            validate={validateLoanPurpose}
          />
      </FormGroup>
    )
  }

  switch(props.product){
    case 'personal-loan':
      return (
        <>
          <FormikControl
            control="input"
            label={copyText.loanInformation.label1}
            name="loanAmount"
            ph="$500-$100,000"
            validate={validateLoanAmount}
            max={20000}
            min={500}
            maxLength={8}
          />
          <FormikControl
            control="select"
            options={purposeOptions}
            label={copyText.loanInformation.label2}
            name="purpose"
            validate={validateLoanPurpose}
          />
      </>
      )
    case 'student-loan-refinance':
      return (
        <>
          <FormikControl
            control="input"
            label={copyText.loanInformation.label1}
            name="loanAmount"
            ph="$500-$100,000"
            validate={validateLoanAmount}
            max={20000}
            min={500}
            maxLength={8}
          />
      </>
      )
    default :
        return null;
  }
}

export default LoanInformation;
