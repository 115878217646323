import React, {useContext} from 'react'

import {BlogContainer, BlogPost, BlogBody, BlogLink, BlogTitle, BlogImage, BlogHeading} from './Styles/Blog'

import {copy} from '../../Helpers/CopyText'

import {AppContext} from '../../Context/Context'



const Blog = () => {
    let context = useContext(AppContext)
    let language = context.language;

    let copyText = copy(language)


    return (
        <>
        <BlogHeading id="Blog">{copyText.blog.header}</BlogHeading>
        <BlogContainer>
            <BlogPost>
                <BlogImage src="https://i.imgur.com/t0XYYcQ.png"/>
                <BlogTitle>{copyText.blog.post1.title}</BlogTitle>
                <BlogBody>{copyText.blog.post1.body}</BlogBody>
                <BlogLink target="_blank" href="https://www.figloans.com/blog/posts/loan-interest-cost-guide">{copyText.blog.linkText}</BlogLink>
            </BlogPost>
            <BlogPost>
                <BlogImage src="https://i.imgur.com/4AMsYFs.png"/>
                <BlogTitle>{copyText.blog.post2.title}</BlogTitle>
                <BlogBody>{copyText.blog.post2.body}</BlogBody>
                <BlogLink target="_blank" href="https://www.figloans.com/blog/posts/what-is-installment-loan-fig-answers">{copyText.blog.linkText}</BlogLink>
            </BlogPost>
            <BlogPost>
                <BlogImage src="https://i.imgur.com/TpbjY54.png"/>
                <BlogTitle>{copyText.blog.post3.title}</BlogTitle>
                <BlogBody>{copyText.blog.post3.body}</BlogBody>
                <BlogLink target="_blank" href="https://www.figloans.com/blog/posts/how-long-does-it-take-to-build-credit">{copyText.blog.linkText}</BlogLink>
            </BlogPost>
        </BlogContainer>
        </>
    )
}

export default Blog
