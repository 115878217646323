export function formatInput(value, name) {
  switch (name) {
    case "loanAmount":
      let newValue = value.toString();
      if(hasLetters(value)){
        return ''
      }
      let loanAmount = newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return '$' + loanAmount;
    case "primaryPhone":
      return formatToPhone(value);
    case "ssn":
      return formatSSN(value);
    case "annualIncome":
      if(hasLetters(value)){
        return ''
      }
      let annualIncome = value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return '$' + annualIncome;
    case 'dateOfBirth':
      return formatDOB(value);
    case 'graduationDate':
      return formatGradDate(value);
    case 'monthlyHousingPayment':
      if(hasLetters(value)){
        return ''
      }
      let monthly = value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return '$' + monthly;
    case 'availableAssets':
      if(hasLetters(value)){
        return ''
      }
      let assets = value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return '$' + assets;
    default:
      return value;
  }
}

export const hasLetters = (value) =>{ 
  var regExp = /[a-zA-Z]/g;
  if(regExp.test(value)){
    return true;
  }else {
    return false;
  }
}

const formatToPhone = (val) => {
  if(hasLetters(val)){
    return ''
  }
  const input = val.replace(/\D/g, "").substring(0, 10);
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);
  if (input.length > 6) {
    return `(${zip}) ${middle} - ${last}`;
  } else if (input.length > 3) {
    return `(${zip}) ${middle}`;
  } else if (input.length > 0) {
    return `(${zip}`;
  }
};

const formatSSN = (val) => {
  if(hasLetters(val)){
    return ''
  }
  const input = val.replace(/\D/g, "").substring(0, 9);
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 5);
  const last = input.substring(5, 9);
  if (input.length > 5) {
    return `${zip}-${middle}-${last}`;
  } else if (input.length > 3) {
    return `${zip}-${middle}`;
  } else if (input.length > 0) {
    return `${zip}`;
  }
};

const formatDOB = val => {
  const input = val.replace(/\D/g, "").substring(0, 8);
  const month = input.substring(0, 2);
  const day = input.substring(2, 4);
  const year = input.substring(4, 8);
  const thisYear = new Date().getFullYear();
  if (input.length > 4) {
    if(parseInt(year) > (thisYear)){
      return `${month}/${day}/${thisYear}`
    }else {
      return `${month}/${day}/${year}`;
    }
  } else if (input.length > 2) {
    if(parseInt(day) > 31){
      return `${month}/31`
    }else {
      return `${month}/${day}`;
    }
  } else if (input.length > 0) {
    if(parseInt(month) > 12){
      return `12`
    }else {
    return `${month}`;
    }
  }
}

const formatGradDate = val => {
  const input = val.replace(/\D/g, "").substring(0, 8);
  const month = input.substring(0, 2);
  const day = input.substring(2, 4);
  const year = input.substring(4, 8);
  if (input.length > 4) {
    return `${month}/${day}/${year}`;
  } else if (input.length > 2) {
    if(parseInt(day) > 31){
      return `${month}/31`
    }else {
      return `${month}/${day}`;
    }
  } else if (input.length > 0) {
    if(parseInt(month) > 12){
      return `12`
    }else {
    return `${month}`;
    }
  }
}