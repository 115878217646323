import styled from 'styled-components';
import {Link} from 'react-router-dom'

export const Container = styled.div`
    min-height: 60vh;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 4rem auto;
`;

export const Logo = styled.img`
    width: 20%;
    max-width: 200px;
    margin: 1.5rem auto;
`;

export const Title = styled.h2`
    margin: 0 auto;
    color: ${props => props.important ? '#3fb39d' : ' #334d5d'};
    width: 70%;
    text-align: center;
    font-size: ${props => props.important ? '1.5rem' : ' 1.3rem'}
`;

export const Text = styled.p`
    text-align: center;
    width: 70%;
`;


export const StyledLink = styled(Link)`
    width: 100%;
`;