import styled from "styled-components";

export const DisclaimerContainer = styled.section`
  width: 80%;
  margin: 2rem auto;
`;

export const Disclaimer = styled.p`
  color: black;

  &:before {
    content: "*";
  }
`;
