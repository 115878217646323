import styled from 'styled-components'

export const BlogContainer = styled.section`
display: flex;
flex-direction: row;
justify-content: space-evenly;
width: 100%;
margin: 0 auto 4rem auto;

@media (max-width: 1000px){
    flex-direction: column;
}
@media (min-width: 2000px){
    width: 80%;
}
`;

export const BlogPost = styled.div`
    width: 30%;
    background-color: #eef7ef;
    padding: .5rem .5rem 3rem .5rem;
    margin: 0 2rem;
    border-radius: 2px;
    box-shadow: 0 0 5px lightgray;

@media (max-width: 1000px){
    flex-direction: column;
    width: 50%;
    margin: 2rem auto;
    display: ${props => props.hide ? 'none' : ''}
}
@media (max-width: 700px){
    width: 70%;
}
@media (min-width: 1500px){
    width: 20%;
}
`;

export const BlogImage = styled.img`
    width: 100%;
`;


export const BlogBody = styled.p`
    color: gray;

    &::after {
        content : ' ...'
    }
`;

export const BlogLink = styled.a`
    text-decoration: underline;
    color: #3fb39d;
    width: 100%;

    &:hover {
        color: #334d5d;
        cursor: pointer;
    }
`;

export const BlogTitle = styled.h3`
    color: #363636;
`;

export const BlogHeading = styled.h2`
    font-size: 2rem;
  text-align: center;
  color: #334d5d;
  margin-top: 3rem;
`;