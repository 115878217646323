import React, {useState} from "react";

import Hero from "../Hero";
import AppFlow from "../AppFlow";
import Scenarios from "../Scenarios";
import Reviews from "../Reviews";
import Faq from "../Faq";
import Blog from '../Blog'
import CaliforniaPop from "../Shared/California";

import MetaDecorator from '../Shared/MetaDecorator'
import FeedbackModal from "../Shared/FeebackModal/FeedbackModal";

const ScrollRender = ({children, show}) => {
  return (
    <React.Fragment>
      {show ? children : <div style={{minHeight:'60vh'}}></div>}
    </React.Fragment>
  )
}

function Home({match}) {

  const [scrolled, setScrolled] = useState(false)
  const [popUp, setPopUp] = useState(true)


  const closeModal = () => {
    setPopUp(false);
  }


  var myListener = function () {
    document.removeEventListener('mousemove', myListener, false);
    window.scrollTo(0, 1)
  };  

  document.addEventListener('mousemove', myListener, false);

  const handleScroll = () => {
    if(!scrolled){
      setScrolled(true)
      window.removeEventListener('scroll', handleScroll)
    }
  }

  if(!scrolled){
    window.addEventListener('scroll', handleScroll)
  }

  return (
    <>
      <MetaDecorator title='FIG Market: Online Loans, Debt Consolidation, Credit Cards and More.' content='Online Personal Loan Application, Credit Card Finder, Savings Accounts Matcher and More'/>
      <Hero match={match}/>
      <AppFlow/>
      <ScrollRender show={scrolled}>
        <Scenarios/>
        <Reviews/>
        <Faq/>
        <Blog/>
        <CaliforniaPop/>
      </ScrollRender>
      <FeedbackModal open={popUp} close={() => closeModal()}/>
    </>
  );
}

export default Home;
