import styled from 'styled-components'

export const DisplayContainer = styled.section`
    min-height: 40vh;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 3rem auto;
    align-items: center;
    justify-content: center;
`;

export const TagLine = styled.h2`
    color: #3fb39d;
    font-size: 2rem;
`;

export const OfferDesc = styled.p`
    color: black;
    width: 50%;
    text-align: center;
    font-size: 1.5rem;
    margin-top: 0;
`;

export const Cta = styled.p`
    color: #334d5d;
    font-size: 1.3rem;
    text-align: center;
    width: 50%;
`;

export const Button = styled.a`
    text-decoration: none;
    text-transform: uppercase;
    background-color: #14E075;
    padding: 1rem;
    color: white;
    border-radius: 5px;

    &:hover {
        transition: ease-in 300ms;
        background-color: rgba(35, 191, 100);
    }
`;

export const Break = styled.hr``;
