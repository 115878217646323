import styled from "styled-components";

import image from '../../../images/header-pattern.png'

export const AdvertiserDisc = styled.p`
  text-decoration: underline;
  color: #3fb39d;
  z-index: 10;
  margin: 0;
  &:hover {
    cursor: pointer;

  }
`;

export const AdvertContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 10;
`;

export const LoanDisplayHeader = styled.div`
  width: 100%;
  height: 50vh;
  background-color: #334d5d;
  background-image: url(${image});
  background-attachment: fixed;
  top: 100px;
  z-index: 0;
`;



export const OfferContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #334d5d;
  background-image: url(${image});
  background-attachment: fixed;
  width: 100%;
  z-index: 10;
  padding-bottom: 2rem;
  box-shadow: inset 0 0 6px gray;
`;



export const DisplayHeader = styled.h2`
  font-size: 2rem;
  text-align: center;
  margin: 2rem;
  color: #3fb39d;
  z-index: -1;
`;

export const LoanDisplayTitle = styled.h2`
  text-align: center;
  width: 80%;
  font-size: 3rem;
  color: #334d5d;
  margin: 1rem auto;

  @media (max-width: 1000px) {
    font-size: 2rem;
  }

  @media (max-width: 360px){
    font-size: 1.5rem;
  }
`;
