import React, {useState} from 'react'
import {SaveData } from '../../../Helpers/FormData'
import { Form, Formik } from "formik";
import {AiFillHome} from 'react-icons/ai'
import {FaArrowLeft} from 'react-icons/fa'
import {Link} from 'react-router-dom'
import Title from "../Formik/FormComponents/Title";
import AdvertisingDisclosure from "../../Shared/Terms/AdvertisingDisclosure";



export function Stepper({display, fieldNames, children, stepParam, history, context, copyText, baseUrl, title, product, ...props}) {
    const [step, setStep] = useState(0);
    const childrenArray = React.Children.toArray(children);
    const currentChild = childrenArray[step];
    const max = childrenArray.length;
  
    if(parseInt(stepParam) !== step){
      setStep(parseInt(stepParam));  }
    
    function nextStep(name, currentValues) {
      SaveData(name, currentValues)
      setStep(((s) => s + 1), history.push(`${baseUrl + (step + 1)}`));
      if(window.dataLayer){
        window.dataLayer.push({
          event : 'Next Step',
          currentStep : step
        })
      }
    }
  
    function previousStep() {
      setStep(((s) => s - 1), history.push(`${baseUrl + (step - 1)}`));
    }
    
    function handleFormText(step){
        if(context.product === 'credit-card'){
            switch(step){
                case 0:
                    return `${copyText.name.tag}`
                case 1:
                    return `${copyText.address.tag}`
                case 2:
                    return `${copyText.contact.tag}`
                case 3:
                    return `${copyText.personalInfo.tag}`
                case 4:
                    return `${copyText.creditInformation.tag}`
                case 5:
                    return `${copyText.creditCardInformation.tag}`
                case 6:
                    return `${copyText.legalInformation.tag}`
                default:
                    return "Your personalized offers await!"
            }
        }else if(context.product === 'savings-account'){
          switch(step){
            case 0:
              return `${copyText.address.tag}`
            case 1:
              return `${copyText.creditInformation.tag}`
            case 2:
              return `${copyText.savingsInformation.tag}`
            default:
              return "Your personalized offers await!"
          }
        }
        else{
            switch(step){
                case 0:
                  return `${copyText.loanInformation.tag}`
                case 1:
                  return `${copyText.creditInformation.tag}`
                case 2:
                  return `${copyText.name.tag}`
                case 3:
                  return `${copyText.address.tag}`
                case 4:
                  return `${copyText.contact.tag}`
                case 5:
                  return `${copyText.personalInfo.tag}`
                case 6:
                  return `${copyText.mortgageInformation.tag}`
                case 7:
                  return `${copyText.financialInformation.tag}`
                case 8:
                  return `${copyText.educationInformation.tag}`
                case 9:
                  return `${copyText.legalInformation.tag}`
                default:
                  return "Your personalized offers await!"
              }
        }
    }

    async function handleContinue(step, validate, name, values){

        let fields = fieldNames;

        if(step === 4){
          const postBody = {
            request : values,
            email : values.email
          }
    
          let postHeader = {
            "Content-Type": "application/json",
            Accept: "application/json",
          }
          fetch('https://app.figloans.com/api/lead', {
            method: 'POST',
            headers : postHeader,
            body: JSON.stringify(postBody),
          })
        }

        async function validation(fieldList) {
            let errors = []
            let fields = fieldList;
            for(let i = 0; i < fields.length; i++){
                let response = await validate(fields[i])
                if(typeof response !== 'undefined'){
                errors.push(response)
                }
            }
            if(errors.length === 0){
                nextStep(name, values, step)
            }else {
                return 'Required';
            }
        }
            validation(fields[step])
    }
  
  
    return (
      <Formik {...props}>
        {({
          validateField, values
        }) => {
          return (
            <Form className="form-master" autoComplete="off">
              <div className="form-top-group">
              {step > 0 && !display ? (
                    <FaArrowLeft onClick={() => previousStep()} className="form-nav"/>
                ) : <Link to="/"><AiFillHome className="form-nav"/></Link>}
                  <AdvertisingDisclosure />
              </div>
              <progress
                className="progress"
                max={max}
                value={step}
                id="progress"
              ></progress>
              <Title
                text={copyText.formTitle[title]}
                info={handleFormText(step)}
              />
              {currentChild}
              <div className="form-button-group">
  
                {step === max - 1 ? (
                  <button className="form-button" id="application-submit" type="submit">Submit</button>
                ) : !display ? (
                  <button
                    className="form-button"
                    type="button"
                    onClick={() => handleContinue(step, validateField, product, values)}
                  >
                    Continue
                  </button>
                ) : null}
              </div>
            </Form>
          );
        }}
      </Formik>
    );
}

export default Stepper