import styled from "styled-components";

import { Link } from "react-router-dom";

export const FormContainer = styled.div`
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;
  background-color: white;
  padding: 1rem 2rem;
  box-shadow: 0 0 5px lightgray;
  border-radius: 10px;
  @media (min-width: 1400px) {
    width: 50%;
  }
  @media (max-width: 960px) {
    justify-content: center;
    text-align: center;
    width: 50%;
    padding: .5rem; 
  }
  @media (max-width: 820px) {
    justify-content: center;
    text-align: center;
    width: 60%;
    padding: .5rem; 
  }
  @media (max-width: 450px) {
    width: 80%;
  }
`;

export const FormSelect = styled.select`
  height: 2.5rem;
  margin: 1rem 0;
  padding: 0 0 0 .5rem;
  font-size: 1.5rem;
  border-radius: 5px;
  border-color: lightgray;

  &:focus {
    outline: none;
  }

  &::after {
    content: '*'
  }

  @media (max-width: 960px) {
    width: 70%;
    margin: 0 auto;
  }

  @media(max-width: 600px){
    width: 100%;
    margin: 0 auto;
  }
`;

export const FormInfo = styled.p`
  font-style: italic;
  font-size: 1.3rem;
`;

export const FormButton = styled.button`
  border-style: none;
  height: 2.5rem;
  background-color: #e37262;
  width: 50%;
  margin: 1rem 0;
  color: white;
  border-radius: 5px;
  font-size: 1.3rem;

  &:hover {
    background-color: #cc503f;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 575px){
    font-size: 1rem;
    width: 60%;
  }
`;

export const FormDisclaimer = styled.p`
  font-style: italic;
  margin: 0;
  padding: 0;
  color: gray;

  &:before {
    content: "*";
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;

  color: white;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const LifeInsuranceButton = styled.a`
  border-style: none;
  height: 2.5rem;
  background-color: #e37262;
  width: 50%;
  margin: 1rem 0;
  color: white;
  border-radius: 5px;
  font-size: 1.3rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #cc503f;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 575px){
    font-size: 1rem;
    width: 60%;
  }
`;
