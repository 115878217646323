import styled from "styled-components";
import image from "../../../images/header-pattern.png";

export const HeroContainer = styled.section`
  width: 100%;
  background: url(${image});
  background-attachment: fixed;
  padding: 6rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 6px gray;


  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const HeroFormContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 960px) {
    width: 100%;
    margin: 1rem;
  }
`;

export const TagLine = styled.h2`
  font-size: 3rem;
  color: #3cb09b;
  width: 50%;
  text-align: center;
  @media (max-width: 960px) {
    width: 80%;
    text-align: center;
  }
  @media (max-width: 620px) {
    font-size: 2.5rem;
  }
`;

export const Accent = styled.span`
  color: #334d5d;
`;


export const ModalOverlayContainer = styled.div`
    position:fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    height:100%;
    width:100%;
    background-color: rgba(0,0,0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LifeInsuranceModal = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 1rem;
  max-height: 65vh;

  @media screen and (max-width: 520px){
    padding: 1.5rem;
  }
`;

export const ModalTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalTitle = styled.h2`
  color: #3cb09b;
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 0;

  @media screen and (max-width: 520px){
    font-size: 1.5rem;
  }
`;

export const ModalTag = styled.p`
  margin-top: 0;
  color: gray;
`;

export const LeapLifeLogo = styled.img`
  height: 2.5rem;

  @media screen and (max-width: 520px){
    height: 2rem;
  }
`;

export const ModalInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalInfoList = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

export const ModalButtonLink = styled.a`
  width: 50%;
  border-style: none;
  padding: 1.2rem;
  border-radius: 10px;
  color: white;
  background-color: #3cb09b;
  font-size: 1.2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: #389c8a;
    cursor: pointer;
  }

  @media screen and (max-width: 520px){
    font-size: 1rem;
    padding: 0.8rem;
    width: 70%;
  }
`;

export const ModalInfoItem = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ModalInfoItemText = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0;
  color: '#3d3d3d';

  @media screen and (max-width: 520px){
    font-size: 1rem;
    text-align: center;
  }
`;

export const ModalInfoItemTag = styled.p`
  margin-top: 0;
  color: #2b2b2b;

  @media screen and (max-width: 520px){
    text-align: center;
  }
`;
