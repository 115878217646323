import React, {useContext} from "react";
import {
  FlowContainer,
  FlowImageContainer,
  FlowImage,
  FlowDesc,
  FlowHeader,
} from "./Styles/AppFlow";

import image1 from "../../images/icons/google-forms.png";
import image2 from "../../images/icons/magnifying-glass.png";
import image3 from "../../images/icons/money.png";

import {copy} from '../../Helpers/CopyText'

import {AppContext} from '../../Context/Context'

function AppFlow() {
  let context = useContext(AppContext)
  let language = context.language;

  let copyText = copy(language)

  return (
    <>
      <FlowHeader>{copyText.appflow.header}</FlowHeader>
      <FlowContainer>
        <FlowImageContainer>
          <FlowImage src={image1}></FlowImage>
          <FlowDesc>
            {copyText.appflow.flow1}
          </FlowDesc>
        </FlowImageContainer>
        <FlowImageContainer>
          <FlowImage src={image2}></FlowImage>
          <FlowDesc>
          {copyText.appflow.flow2}
          </FlowDesc>
        </FlowImageContainer>
        <FlowImageContainer>
          <FlowImage src={image3}></FlowImage>
          <FlowDesc>
          {copyText.appflow.flow3}
          </FlowDesc>
        </FlowImageContainer>
      </FlowContainer>
    </>
  );
}

export default AppFlow;
