import React, {useContext} from "react";
import {AppContext} from '../../../../Context/Context'
import {copy} from '../../../../Helpers/CopyText'
import FormikControl from "../FormikControl";
import { FormGroup } from "./Styles/HelperComponents";




const hasNumber = (value) => {
  return /\d/.test(value);
}

const validateZip = (value) => {
  let error;
  if(value.length > 5){
    error = 'Please Enter a Valid Zip Code'
  }else if(value.length !== 5) {
    error = 'Invalid Zip Code'
  }
  return error;
}

const validateAddress = (value) => {
  let error;
  if(value === ''){
    error = "Address Required"
  }
  return error;
}

const validateCity = (value) => {
  let error;
  if(value === ''){
    error = 'City Required'
  }else if(hasNumber(value)){
    error = "Please Enter a Valid City Name"
  }
  return error;
}

const validateState = value => {
  let error;
  if(value === ''){
    error = ""
  }
  return error;
}

function Address(props) {
  const states = [
    "",
    "AL",
    "AK",
    "AS",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FM",
    "FL",
    "GA",
    "GU",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MH",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "MP",
    "OH",
    "OK",
    "OR",
    "PW",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VI",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  let context = useContext(AppContext)
  let language = context.language
  let copyText = copy(language)

  if(context.product === 'savings-account'){	
    return (	
      <FormikControl	
      control="input"	
      type="text"	
      label={copyText.address.label4}	
      name="zipcode"	
      ph="10001"	
      maxLength={5}	
      validate={validateZip}	
      />	
    )	
  }

    return (
      <>
      <FormGroup>
        <FormikControl
        control="input"
        type="text"
        label={copyText.address.label1}
        name="address1"
        ph="123 Main St"
        validate={validateAddress}
        />
        <FormikControl
        control="input"
        type="text"
        label={copyText.address.label2}
        name="address2"
        ph="2nd Floor"
        />
      </FormGroup>
      <FormGroup>
      <FormikControl
          control="input"
          type="text"
          label={copyText.address.label3}
          name="city"
          ph="New York"
          validate={validateCity}
        />
        <FormikControl
        control="input"
        type="text"
        label={copyText.address.label4}
        name="zipcode"
        ph="10001"
        maxLength={5}
        validate={validateZip}
      />
      </FormGroup>
      <FormGroup>
      <FormikControl
          extraClass="single-select"
          control="select"
          options={states.map((state) => ({ key: state, value: state }))}
          label={copyText.address.label5}
          name="state"
          validate={validateState}
        />
      </FormGroup>
      </>
    )

  // return (
  //   <>
  //     <FormikControl
  //       control="input"
  //       type="text"
  //       label="Address 1"
  //       name="address1"
  //       ph="335 Madison Ave"
  //       validate={validateAddress}
  //     />
  //     <FormikControl
  //       control="input"
  //       type="text"
  //       label="Address 2"
  //       name="address2"
  //       ph="16th Floor"
  //     />
  //       <FormikControl
  //         control="input"
  //         type="text"
  //         label="City"
  //         name="city"
  //         ph="New York"
  //         validate={validateCity}
  //       />
  //       <FormikControl
  //         control="select"
  //         options={states.map((state) => ({ key: state, value: state }))}
  //         label="State"
  //         name="state"
  //         validate={validateState}
  //       />
  //     <FormikControl
  //       control="input"
  //       type="text"
  //       label="Zip Code"
  //       name="zipcode"
  //       ph="10017"
  //       maxLength={5}
  //       validate={validateZip}
  //     />
  //   </>
  // );
}

export default Address;
