import React from "react";

import { Field } from "formik";


import {LabelContainer} from './Styles/Styles'

import { formatInput } from "../../../../Helpers/Formatting";

function DateInput(props) {
    const { label, name, ph, info, maxLength, max, min, validate, type, ...restProps } = props;

    return (
        <div className="form-control">
        <Field placeholder={ph} id={name} name={name} validate={validate} {...restProps}>
          {({ field, form }) => {
            const { setFieldValue } = form;
            return (
                <>
                <LabelContainer>
              <label className={typeof form.errors[name] !== 'undefined' && form.touched[name] ? `form-label error-message` : `form-label`} htmlFor={name}>
                {typeof form.errors[name] !== 'undefined' && form.touched[name] ? `${form.errors[name]}` : `${label}`}
                {info}
              </label>
              </LabelContainer>
              <input
                maxLength={maxLength}
                id={name}
                {...field}
                {...restProps}
                placeholder={ph}
                onChange={(e) =>
                  setFieldValue(name, formatInput(e.target.value, name))
                }
              />
              </>
            );
          }}
        </Field>
      </div>
    )
}

export default DateInput
