import React, {useState, useContext} from 'react'
import { Container, FeedbackForm, NameInput, Comments, ErrorMessage, TagContainer, SubmitBtn, Label, TagLine, TitleContainer, Close } from './Styles/FeedbackModal'

import {BsChevronUp, BsChevronDown} from 'react-icons/bs'

import {AppContext} from '../../../Context/Context'
import {copy} from '../../../Helpers/CopyText'

import {sendFeedback} from '../../../Helpers/Feedback'

import {validateEmail} from '../../Forms/Formik/FormComponents/Contact'

function FeedbackModal({open, close}) {

    let context = useContext(AppContext)
    let language = context.language
    let copyText = copy(language)

    const [name, setName] = useState('')
    const [comments, setComments] = useState('')
    const [email, setEmail] = useState('')

    const [formOpen, setFormOpen] = useState(false)

    const [errorMessage, setErrorMessage] = useState('')

    const handleSubmit = (name, email, comments) => {
        if(name.length < 1){
            setErrorMessage('Name Required')
        }else if(email.length < 1){
            setErrorMessage('Email Required')
        }else if(comments.length < 1){
            setErrorMessage('Comments Required')
        }else{
            if(validateEmail(email) === ''){
                setErrorMessage('Success')
                setName('')
                setEmail('')
                setComments('')
                sendFeedback(name, email, comments)
            }else {
                setErrorMessage(validateEmail(email))
            }
        }
    }

    if(!open){
        return null;
    }

    return (
    <Container>
        <TitleContainer>
            <TagContainer onClick={() => setFormOpen(s => !s)}>
                <TagLine>{copyText.feedbackModal.tag}</TagLine>
                {
                    formOpen ? <BsChevronUp/> : <BsChevronDown/>
                }
            </TagContainer>
            <Close onClick={close}>X</Close>
        </TitleContainer>
        {
            formOpen ? 
            <FeedbackForm action="mailto:support@figloans.com" method="post" name="FeedbackForm" enctype="text/plain">
                <Label htmlFor="name">{copyText.feedbackModal.label1}</Label>
                <NameInput name="name" type="text" value={name} onChange={(e) => setName(e.target.value)}></NameInput>
                <Label htmlFor="email">{copyText.feedbackModal.label2}</Label>
                <NameInput name="email" type="text" value={email} onChange={(e) => setEmail(e.target.value)}></NameInput>
                <Label htmlFor="comments">{copyText.feedbackModal.label3}</Label>
                <Comments name="comments" type='text' value={comments} onChange={(e) => setComments(e.target.value)}></Comments>
                {errorMessage && errorMessage.length >= 1 ? <ErrorMessage success={errorMessage === 'Success'}>{errorMessage}</ErrorMessage> : null}
                <SubmitBtn type="submit" onClick={() => handleSubmit(name, email, comments)}>{copyText.feedbackModal.button1}</SubmitBtn>
            </FeedbackForm> : null
        }
     </Container>
    )
}

export default FeedbackModal