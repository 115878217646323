import React, {useState, useContext, useEffect} from "react";
import ProductSelector from "./ProductSelector";
import {
  HeroContainer,
  TagLine,
  HeroFormContainer,
  Accent,
  ModalOverlayContainer,
  LifeInsuranceModal,
  ModalTitleContainer,
  ModalTitle,
  ModalTag,
  ModalInfoContainer,
  ModalInfoList,
  ModalButton,
  LeapLifeLogo,
  ModalInfoItemTag,
  ModalInfoItem,
  ModalInfoItemText,
  ModalButtonLink,
} from "./Styles/Hero";

import {AppContext} from '../../Context/Context'

import {copy} from '../../Helpers/CopyText'

// Life Insurance Icon Imports
import {AiOutlineSearch, AiOutlineClockCircle} from 'react-icons/ai'
import {BiBadgeCheck} from 'react-icons/bi'
import "./Styles/Hero.css"


function Hero({match}) {
  let context = useContext(AppContext)
  let language = context.language;
  let copyText = copy(language)

  const [currentLinkId, setCurrentLinkId] = useState(1);
  const [lifeInsurance, setLifeInsurance] = useState(false)

  useEffect(() => {
    switch(match.params.product){
      case 'personal-loan':
        setCurrentLinkId(1)
        break;
      case 'savings':
        setCurrentLinkId(2)
        break;
      case 'life-insurance':
        setLifeInsurance(true)
        setCurrentLinkId(3)
        break;
      case 'credit-cards':
        setCurrentLinkId(4)
      case 'student':
        setCurrentLinkId(5)
      default:
        setCurrentLinkId(1)
        break;
    }
  }, [])
  
  const handleRoute = (linkId) => {
    switch (currentLinkId) {
      case 1:
        return "/personal-loan/application/0";
      case 2:
        return "/savings-account";
      case 3:
        return "/";
      case 4:
        return "/";
      case 5:
        return "/"
      default:
        break;
    }
  };

  const handleTagline = (linkId) => {
    switch(linkId){
      case 1:
        return `${copyText.hero.tag1}`
      case 2:
        return `${copyText.hero.tag2}`
      case 3:
        return `${copyText.hero.tag3}`
      case 4:
        return `${copyText.hero.tag4}`
      case 5:
        return `${copyText.hero.tag5}`
      default:
        return 'Coming Soon!'
    }
  }

  const handleButton = (linkId) => {
    switch (linkId){
      case 1:
        return `${copyText.hero.button1}`
      case 2:
        return `${copyText.hero.button2}`
      case 3:
        return `${copyText.hero.button3}`
      case 4:
        return `${copyText.hero.button4}`
      case 5:
        return `${copyText.hero.button5}`
      default:
        return 'Coming Soon!'
    }
  }

  const handleChange = (e) => {
    e.preventDefault();
    setCurrentLinkId(parseInt(e.target.value));
  };

  const submitLifeInsuranceClick = () => {
    if(window.dataLayer){
      window.dataLayer.push({
        event : 'life_insurance',
      })
    }
  }


  return (
    <HeroContainer>
      <TagLine>
        {handleTagline(currentLinkId)}
        <br /> {currentLinkId <= 3 ? <Accent>{copyText.hero.tagEnd}</Accent> : null}
      </TagLine>
      <HeroFormContainer>
        <ProductSelector setLifeInsurance={setLifeInsurance} handleButton={handleButton} handleChange={handleChange} handleRoute={handleRoute} currentLinkId={currentLinkId}/>
      </HeroFormContainer>
      {lifeInsurance ?
        <ModalOverlayContainer>
          <LifeInsuranceModal>
              <p onClick={() => {setLifeInsurance(false)}} className="modal-close-x">X</p>
              <ModalTitleContainer>
                <ModalTitle>Get Matched With Quotes from Top Providers</ModalTitle>
                <ModalTag>via Our Trusted Partner</ModalTag>
                <LeapLifeLogo src={'https://images.evenfinancial.com/logos/leaplife/leap-logo-green.svg'}/>
              </ModalTitleContainer>
              <ModalInfoContainer>
                <ModalInfoList>
                  <ModalInfoItem>
                    <ModalInfoItemText><AiOutlineSearch className="modal-info-icon"/>Browse Their Marketplace</ModalInfoItemText>
                    <ModalInfoItemTag>All partners are A and A+ rates life insurance carriers,</ModalInfoItemTag>
                  </ModalInfoItem>
                  <ModalInfoItem>
                    <ModalInfoItemText><BiBadgeCheck className="modal-info-icon"/>Pick Your Plan</ModalInfoItemText>
                    <ModalInfoItemTag>Answer a few questions and get matched with a provider,</ModalInfoItemTag>
                  </ModalInfoItem>
                  <ModalInfoItem>
                    <ModalInfoItemText><AiOutlineClockCircle className="modal-info-icon"/>Apply in minutes</ModalInfoItemText>
                    <ModalInfoItemTag>It's really that easy!</ModalInfoItemTag>
                  </ModalInfoItem>
                </ModalInfoList>
                <ModalButtonLink onClick={() => {submitLifeInsuranceClick()}} href="https://www.leaplife.com/partner/fig/life-insurance" target="_blank">Continue</ModalButtonLink>
              </ModalInfoContainer>
          </LifeInsuranceModal>
        </ModalOverlayContainer>
        : null}
    </HeroContainer>
  );
}

export default Hero;
