import React from 'react'

import {DisplayContainer, Container, TagLine, OfferDesc, Cta, Button, Break} from './Styles/NoOffers'

function NoOffers({history}) {
    return (
        <DisplayContainer>
        <Container>
            <TagLine>{"We're"} sorry,</TagLine>
            <OfferDesc>
                We are working hard everyday to expand our loan servicing network, unfortunately at this time we are unable to match you with any offers.
            </OfferDesc>
            <Break />
            <Cta>To continue your search, we recommend our partner MoneyLion</Cta>
            <Button target="_blank" href="https://hifiona.com/figloans/loans?tag.source=marketplace">Continue</Button>
        </Container>
        </DisplayContainer>
    )
}

export default NoOffers;
