import React from "react";

import ReactTooltip from "react-tooltip";

import styled from "styled-components";

import "./Styles/styles.css";

const Disclosure = styled.p`
  display: flex;
  margin-left: auto;
  margin-top: 0;
  padding-top: 0;
  color: #3fb39d;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;

function AdvertisingDisclosure() {
  return (
    <>
      <Disclosure data-tip data-for="AdvertisingDisclosure">
        Advertising Disclosure
      </Disclosure>
      <ReactTooltip
        className="disclosure"
        id="AdvertisingDisclosure"
        type="dark"
        delayHide={1000}
      >
        <span>
          *The offers that appear are from companies which FIG Marketplace,
          and its partners receive compensation. This compensation may influence
          the selection, appearance, and order of appearance of the offers
          listed below. However, this compensation also facilitates the
          provision by Engine by MoneyLion of certain services to you at no charge.
          The offers shown below do not include all financial services companies
          or all of their available product and service offerings.
        </span>
      </ReactTooltip>
    </>
  );
}

export default AdvertisingDisclosure;
