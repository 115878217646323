import React, {useState} from "react";

import { Field } from "formik";


import {LabelContainer, ResultContainer, Result} from './Styles/Styles'

import { formatInput } from "../../../../Helpers/Formatting";

function Search(props) {
  const { universities, label, name, ph, validate, ...restProps } = props;

  const [selected, setSelected] = useState(false)

  return (
      <div className="form-control">
        <Field placeholder={ph} id={name} name={name} validate={validate} {...restProps}>
          {({ field, form}) => {
            let libData = [];
            let searchKey = field.value.trim().toLowerCase();
            const { setFieldValue } = form;
            if(searchKey && searchKey.length > 0){
              libData = universities.filter(i => {
                return i.toLowerCase().match(searchKey)
              })
            }
            return (
              <>
              <LabelContainer>
              <label className={typeof form.errors[name] !== 'undefined' && form.touched[name] ? `form-label error-message` : `form-label`} htmlFor={name}>
                {typeof form.errors[name] !== 'undefined' && form.touched[name] ? `${form.errors[name]}` : `${label}`}
              </label>
              </LabelContainer>
              <input
                className={typeof form.errors[name] !== 'undefined' && form.touched[name] ? 'error' : ''}
                id={name}
                {...field}
                {...restProps}
                placeholder={ph}
                onChange={(e) => {
                  setFieldValue(name, formatInput(e.target.value, name))
                  if(!field.value){
                    setSelected(false)
                  }
                }
                }
              />
              {
                !selected && libData.length > 1 ? <ResultContainer>
                {
                  libData.map((item, i) => {
                    if(i < 5){
                      return(
                      <Result onClick={() => {
                        setFieldValue(name, item)
                        setSelected(true)
                      }} key={i}>{item}</Result>
                    )
                    }
                    return null;
                  })
                }
              </ResultContainer> : null
              }
              </>
            );
          }}
        </Field>
      </div>
  );
}

export default Search;
