import React, { useState, useContext } from "react";
import { FaqContainer, FaqHeader, FaqCTA, FaqButton, CTAContainer } from "./Styles/Faq";
import FaqSection from "./FaqSection";

import {Link} from 'react-router-dom'

import {copy} from '../../Helpers/CopyText'

import {AppContext} from '../../Context/Context'


function Faq() {
  let context = useContext(AppContext)
  let language = context.language

  let copyText = copy(language)

  const [questionOpen, setQuestionOpen] = useState([
    {
      open: false,
    },
    {
      open: false,
    },
    {
      open: false,
    },
    {
      open: false,
    },
  ]);

  let questions = [
    {
      question: copyText.faq.question1.question,
      answer: copyText.faq.question1.answer,
    },
    {
      question: copyText.faq.question2.question,
      answer: copyText.faq.question2.answer,
    },
    {
      question: copyText.faq.question3.question,
      answer: copyText.faq.question3.answer,
    },
    {
      question: copyText.faq.question4.question,
      answer: copyText.faq.question4.answer,
    },
  ]

  const toggleAnswer = (index) => {
    setQuestionOpen(
      questionOpen.map((question, i) => {
        if (i === index) {
          question.open = !question.open;
        }
        return question;
      })
    );
  };

  return (
    <>
      <FaqHeader id="FAQ">{copyText.faq.header}</FaqHeader>
      <FaqContainer>
        {questions.map((group, index) => (
          <FaqSection
            toggleAnswer={toggleAnswer}
            question={group.question}
            answer={group.answer}
            active={questionOpen[index].open}
            index={index}
            key={index}
          />
        ))}
      </FaqContainer>
      <CTAContainer>
      <FaqCTA>{copyText.faq.cta.cta1}<br/>{copyText.faq.cta.cta2}</FaqCTA>
      <Link to="/personal-loan">
        <FaqButton>{copyText.faq.button}</FaqButton>
      </Link>
      </CTAContainer>
    </>
  );
}

export default Faq;
