import React from "react";

import { ErrorMessage } from "formik";

import TextError from "./TextError";

import { Field } from "formik";

function TextArea(props) {
  const { label, name, validate, ...restProps } = props;
  return (
    <div className="form-control">
      <label className="form-label" htmlFor={name}>
        {label}
      </label>
      <Field as="textarea" id={name} validate={validate} name={name} {...restProps} />
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

export default TextArea;
