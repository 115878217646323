import React, { useEffect, useContext, useState } from "react";

// import response from "../../Helpers/response.json";
// import pendingresponse from "../../Helpers/pendingresponse.json";
// import emptyresponse from "../../Helpers/testemptyresponse.json";

import { ImHourGlass } from "react-icons/im";

import {
  OfferContainer,
  AdvertiserDisc,
  AdvertContainer,
} from "./Styles/LoanDisplay";

import { LoanDisplayTitle } from "./Styles/LoanDisplay";

import LoanOffer from "./Offer";

import Delay from "../../Helpers/Delay";

import ReactTooltip from "react-tooltip";

import "./Styles/LoanDisplay.css";

import { getLeadRateTable, getRateTable } from "../../Helpers/apicall";

import { AppContext } from "../../Context/Context";
import NoOffers from "./NoOffers";
import Blog from "../Blog";

import { copy } from "../../Helpers/CopyText";

export const AdvertDisclaimer = () => {
  return (
    <AdvertContainer style={{ zIndex: 100 }}>
      <AdvertiserDisc data-tip data-for="advertiser">
        Advertiser Disclosure
      </AdvertiserDisc>
      <ReactTooltip
        className="advertiser"
        id="advertiser"
        place="bottom"
        effect="solid"
      >
        <span>
          The offers that appear are from companies which Fig Tech, Inc., and
          its partners receive compensation. This compensation may influence the
          selection, appearance, and order of appearance of the offers listed
          below. However, this compensation also facilitates the provision by
          Fig Tech of certain services to you at no charge. The offers shown
          below do not include all financial services companies or all of their
          available product and service offerings.
        </span>
      </ReactTooltip>
    </AdvertContainer>
  );
};

const SortSelector = ({ stateFunc }) => {
  return (
    <div className="sort-container">
      <label className="sort-label" htmlFor="sort-selector">
        Sort:
      </label>
      <select
        onChange={(e) => stateFunc(e)}
        className="sort-select"
        name="sort-selector"
        id="sort-selector"
      >
        <option value="recommended">Recommended</option>
        <option value="loanAmount">Loan Amount (High - Low)</option>
        <option value="apr">APR (Low - High)</option>
      </select>
    </div>
  );
};

const PendingDisplay = () => {
  return (
    <div className="pending-container">
      <ImHourGlass className="pending-icon" />
      <p>Awaiting Additional Responses...</p>
    </div>
  );
};

function LoanDisplay({ data, history, match, removeUnload }) {
  const context = useContext(AppContext);
  let language = context.language;
  let copyText = copy(language);

  const [loading, setLoading] = useState(true);

  const [stuck, setStuck] = useState(false);

  const [awaiting, setAwaiting] = useState(true);

  const [offer, setOffer] = useState(data);

  const [sortedOffers, setSortedOffers] = useState();

  const [groupedOffers, setGroupedOffers] = useState([]);

  const [sortBy, setSortBy] = useState("recommended");

  const [loanAmount, setLoanAmount] = useState(false);
  const [apr, setApr] = useState(false);
  const [recommended, setRecommended] = useState(false);

  const handleOffer = (data) => {
    let groupedOffers = {};
    setOffer(data);
    let offers = data.loanOffers;
    if (!offers) return;

    offers.forEach((offer) => {
      let name = offer.originator.name;

      if (groupedOffers[name]) {
        groupedOffers[name].push(offer);
      } else {
        groupedOffers[name] = [offer];
      }

      groupedOffers[name].sort((a, b) => {
        return b.recommendationScore - a.recommendationScore;
      });
    });
    setGroupedOffers(groupedOffers);
    setSortedOffers(Object.values(groupedOffers));
  };

  useEffect(() => {
    // if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    //   if (!offer) {
    //     handleOffer(emptyresponse);
    //   }
    //   let cycle = setTimeout(() => handleOffer(emptyresponse), 3000);
    //   if (
    //     loading &&
    //     offer &&
    //     offer.loanOffers &&
    //     offer.loanOffers.length === 0 &&
    //     offer.pendingOriginators &&
    //     offer.pendingOriginators.length > 0
    //   ) {
    //     setTimeout(() => {
    //       if (loading) {
    //         clearTimeout(cycle);
    //         setLoading(false);
    //         setStuck(true);
    //       }
    //     }, 4000);
    //   }
    // } else
    if (!offer) {
      if (match.params.uuid) {
        let uuid = match.params.uuid;
        getLeadRateTable(uuid).then((data) => {
          handleOffer(data);
        });
      } else if (
        context[context.productCode] &&
        context[context.productCode].consentsToTcpa
      ) {
        let sendData = context[context.productCode];
        getRateTable(sendData, context.product, context.utm).then((data) => {
          handleOffer(data);
        });
      } else {
        history.push("/");
      }
    } else if (
      offer &&
      offer.pendingOriginators &&
      offer.pendingOriginators.length > 0
    ) {
      // if still offers pending
      let cycle = setTimeout(
        () =>
          getLeadRateTable(offer.uuid).then((data) => {
            handleOffer(data);
          }),
        3000
      );

      if (
        loading &&
        offer &&
        offer.loanOffers &&
        offer.loanOffers.length === 0 &&
        offer.pendingOriginators &&
        offer.pendingOriginators.length > 0
      ) {
        setTimeout(() => {
          if (loading) {
            clearTimeout(cycle);
            setLoading(false);
            setStuck(true);
          }
        }, 30000);
      }

      if (loading && offer && offer.loanOffers && offer.loanOffers.length > 0) {
        window.scrollTo(0, 0);
        setLoading(false);
      }
    } else if (loading) {
      // when all calls finished
      if (
        offer &&
        offer.loanOffers &&
        offer.loanOffers.length === 0 &&
        offer.specialOffers.length > 0
      ) {
        // if no loan offers and yes special offers, redirect to offer
        removeUnload();
        const specialOffer = offer.specialOffers[0];
        window.location.href = specialOffer.url;
      }
      window.scrollTo(0, 0);
      setLoading(false);
    }
    if (
      offer &&
      offer.pendingOriginators &&
      offer.pendingOriginators.length < 1
    ) {
      setAwaiting(false);
    }
  }, [offer]);

  if (offer?.loanOffers) {
    if (
      offer.loanOffers.length === 0 &&
      (offer.pendingOriginators.length === 0 || stuck)
    ) {
      return (
        <>
          <NoOffers />
          <Blog />
        </>
      );
    }
  }

  const handleSorting = (e) => {
    setSortBy(e.target.value);
  };

  if (sortBy === "loanAmount" && groupedOffers && !loanAmount) {
    let sort;
    if (!Object.values) {
      sort = Object.keys(groupedOffers).map((e) => groupedOffers[e]);
    } else {
      sort = Object.values(groupedOffers);
    }
    sort.forEach((offer) => {
      offer.sort((a, b) => {
        return b.maxAmount - a.maxAmount;
      });
    });
    sort.sort((a, b) => {
      return b[0].maxAmount - a[0].maxAmount;
    });
    setRecommended(false);
    setApr(false);
    setLoanAmount(true);
    setSortedOffers(sort);
    return;
  }
  if (sortBy === "apr" && groupedOffers && !apr) {
    let sort;
    if (!Object.values) {
      sort = Object.keys(groupedOffers).map((e) => groupedOffers[e]);
    } else {
      sort = Object.values(groupedOffers);
    }
    sort.forEach((offer) => {
      offer.sort((a, b) => {
        return a.maxApr - b.maxApr;
      });
    });
    sort.sort((a, b) => {
      return a[0].maxApr - b[0].maxApr;
    });
    setLoanAmount(false);
    setRecommended(false);
    setApr(true);
    setSortedOffers(sort);
    return;
  }
  if (sortBy === "recommended" && offer && !recommended) {
    setLoanAmount(false);
    setApr(false);
    setRecommended(true);
    handleOffer(offer);
  }

  return (
    <>
      <Delay loading={loading}>
        <OfferContainer>
          <LoanDisplayTitle>
            {copyText.loanDisplay.title1}
            <br />
            {copyText.loanDisplay.title2}
            <span className="emphasis">
              {offer && offer.loanOffers ? offer.loanOffers.length : null}
            </span>
            {copyText.loanDisplay.title3}
            {offer && offer.loanOffers && offer.loanOffers.length > 1
              ? "s"
              : ""}
            !
          </LoanDisplayTitle>
          <AdvertDisclaimer />
          <SortSelector stateFunc={handleSorting} />
          {sortedOffers
            ? sortedOffers.map((offers, i) => {
                return (
                  <LoanOffer master={offer} offers={offers} key={i} i={i} />
                );
              })
            : null}
          {awaiting ? <PendingDisplay /> : null}
        </OfferContainer>
      </Delay>
      <Blog />
    </>
  );
}

export default LoanDisplay;
