import React, {useContext} from "react";
import {AppContext} from '../../../../Context/Context'
import {copy} from '../../../../Helpers/CopyText'
import FormikControl from "../FormikControl";
import { FormGroup } from "./Styles/HelperComponents";
const validateDate = (value) => {
  let error;
  if(!value){
    error = 'Date of Birth Required!'
    return error;
  }
  let dateString = value.replace(/\D/g, "").substring(0, 8);
  const thisYear = new Date().getFullYear();
  if(value.length < 10){
    error = "Date of Birth Required"
  }else if(value.length === null){
    error = "Date of Birth Required"
  }else if(parseInt(dateString.substring(4, 8)) + 18 > thisYear){
    error = 'You must be at least 18 years of age'
  }
  return error;
}

const validateCitizenship = (value) => {
  let error;
  if(!value) {
    error = "Pay Schedule Required"
    return error;
  }
  if(value === ''){
    error = "Pay Schedule Required"
    return error;
  }
  return error;
}

const validateSSN = (value) => {
  let error;
  if(!value || typeof value === 'undefined'){
    error = 'SSN# Required'
    return error;
  }
  if(value.length < 11){
    error = "Invalid SSN#"
  }else if(value.length === 0){
    error = "SSN# Required"
  }
  return error;
}

const citizenshipOptions = [
  {key: 'Citizen', value: 'citizen'},
  {key: 'Permanent Resident', value: 'permanent_resident'},
  {key: 'Other', value: 'other'},
]

function PersonalInfo(props) {


  let context = useContext(AppContext)
  let language = context.language
  let copyText = copy(language)

  if(props.single){
    return (
      <FormGroup>
        <FormikControl
        control="dateinput"
        label={copyText.personalInfo.label1}
        name="dateOfBirth"
        validate={validateDate}
        maxLength={10}
        ph={'MM/DD/YYYY'}
      />
      <FormikControl
        control="input"
        type="text"
        label={copyText.personalInfo.label2}
        name="ssn"
        ph="###-##-####"
        maxLength={11}
        validate={validateSSN}
      />
      </FormGroup>
    )
  }

  switch(props.product){
    case 'personal-loan':
      return (
        <>
          <FormikControl
            control="dateinput"
            label={copyText.personalInfo.label1}
            name="dateOfBirth"
            validate={validateDate}
            maxLength={10}
            ph={'MM/DD/YYYY'}
          />
          <FormikControl
            control="input"
            type="text"
            label={copyText.personalInfo.label2}
            name="ssn"
            ph="###-##-####"
            maxLength={11}
            validate={validateSSN}
          />
        </>
      );
    case 'student-loan-refinance':
      return (
        <>
          <FormikControl
            control="dateinput"
            label={copyText.personalInfo.label1}
            name="dateOfBirth"
            validate={validateDate}
            maxLength={10}
            ph={'MM/DD/YYYY'}
          />
          <FormikControl
            control="input"
            type="text"
            label={copyText.personalInfo.label2}
            name="ssn"
            ph="###-##-####"
            maxLength={11}
            validate={validateSSN}
          />
          <FormikControl
            control="select"
            label={copyText.personalInfo.label3}
            name="citizenshipStatus"
            options={citizenshipOptions}
            validate={validateCitizenship}
            />
        </>
      );
      case 'credit-card':	
        return (	
          <>	
          <FormikControl	
            control="dateinput"	
            label={copyText.personalInfo.label1}	
            name="dateOfBirth"	
            validate={validateDate}	
            maxLength={10}	
            ph={'MM/DD/YYYY'}	
          />	
        </>	
        );
      default:
        return null;
  }
}

export default PersonalInfo;
