import React, { Suspense, lazy, useEffect } from "react";
import { createGlobalStyle } from "styled-components";

import Header from "./components/Header";
import Footer from "./components/Footer";

import LoanForm from "./components/Forms/LoanForm";
import Home from "./components/Home";
import LoanDisplay from "./components/LoanDisplay";
import SavingsDisplay from "./components/SavingsDisplay";

// import StudentRefi from './components/Forms/StudentRefi'
// import CreditCard from './components/Forms/CreditCard'
// import StaticDisplay from "./components/LoanDisplay/StaticDisplay";

import Context from "./Context";

import "./App.css";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import image from "./images/so-white.png";

import ErrorBoundary from "./components/ErrorBoundary";

// Imports for CSS Transitions between Routes in Switch
import { TransitionGroup, CSSTransition } from "react-transition-group";

// Lazy loading to increase speed scoring
const Blog = lazy(() => import("./components/BlogPort/"));
const PrivacyPolicy = lazy(() => import("./components/Shared/PrivacyPolicy"));
const TermsOfService = lazy(() => import("./components/Shared/TermsOfService"));
const DoNotSell = lazy(() => import("./components/Shared/DoNotSell"));
const LoanCalculator = lazy(() => import("./components/LoanCalculator"));

const GlobalStyle = createGlobalStyle`
  html,
  body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    width: 100%;
    background-color: #FFF;
    background-image: url(${image});
    -webkit-appearance: none;
    overflow-x: hidden;
  }
  html::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }

  html::-webkit-scrollbar{
    width: 10px;
    background-color: #F5F5F5;
  }
  html::-webkit-scrollbar-thumb{
    background-color: #3fb39d;
    background-image: -webkit-linear-gradient(45deg,
                                              rgba(255, 255, 255, .2) 25%,
                          transparent 25%,
                          transparent 50%,
                          rgba(255, 255, 255, .2) 50%,
                          rgba(255, 255, 255, .2) 75%,
                          transparent 75%,
                          transparent)
  }
`;

const App = () => {
  const beforeUnloadListener = (event) => {
    event.preventDefault();
    return (event.returnValue = "Are you sure you want to leave this site?");
  };

  useEffect(() => {
    window.addEventListener("beforeunload", beforeUnloadListener);
    return window.removeEventListener("beforeunload", beforeUnloadListener);
  }, []);

  const removeUnload = () => {
    window.removeEventListener("beforeunload", beforeUnloadListener);
  };

  return (
    <Context>
      <Router>
        <GlobalStyle />
        <ErrorBoundary>
          <Header />
          <Suspense fallback={<div>Loading...</div>}>
            <Route
              render={({ location }) => (
                <TransitionGroup>
                  <CSSTransition
                    key={location.key}
                    timeout={500}
                    classNames="my-node"
                  >
                    <Switch>
                      {/* <Route path="/student-loan-refinance/application/:step" render={(props) => <StudentRefi {...props}/>}/>	 */}
                      {/* <Route path="/credit-card/application/:step" render={props => <CreditCard {...props}/>}/>	 */}
                      {/* <Route path="/savings-account/application/:step" render={props => <SavingsAccount {...props}/>}/> */}
                      <Route
                        path="/personal-loan/application/single-page"
                        render={(props) => <LoanForm singlePage {...props} />}
                      />
                      <Route
                        path="/personal-loan/application/:step"
                        render={(props) => <LoanForm {...props} />}
                      />
                      {/* <Route
                    path="/savings-account/application/:step"
                    render={props => <SavingsAccount {...props}/>}
                    /> */}
                      <Route
                        path="/savings-account"
                        render={(props) => <SavingsDisplay {...props} />}
                      />
                      <Route
                        path="/offers/:uuid"
                        render={(props) => (
                          <LoanDisplay removeUnload={removeUnload} {...props} />
                        )}
                      />
                      <Route
                        path="/offers"
                        render={(props) => (
                          <LoanDisplay removeUnload={removeUnload} {...props} />
                        )}
                      />
                      <Route
                        exact
                        path="/privacy-policy"
                        component={PrivacyPolicy}
                      />
                      <Route
                        path="/terms-of-service"
                        component={TermsOfService}
                      />
                      <Route path="/personal/opt-out" component={DoNotSell} />
                      <Route
                        path="/tools/loan-calculator"
                        component={LoanCalculator}
                      />
                      <Route
                        path="/error/test"
                        render={(props) => (
                          <ErrorBoundary force={true} {...props} />
                        )}
                      />
                      <Route
                        path="/blog"
                        render={(props) => <Blog {...props} />}
                      />
                      <Route
                        path={["/:product", "/"]}
                        render={(props) => <Home {...props} />}
                      />
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              )}
            />
          </Suspense>
          <Footer />
        </ErrorBoundary>
      </Router>
    </Context>
  );
};

export default App;
