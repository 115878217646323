import React, { useState} from "react";
import FormikControl from "../FormikControl";

import styled from "styled-components";

import Modal from "../../../Modal";

import Terms from "../../../Shared/Terms/Terms";

import {Link} from 'react-router-dom'

const TosLink = styled.a`
  text-decoration: underline;
  color: #3fb39d;

  &:hover {
    cursor: pointer;
  }
`;

const TosInfo = styled.p`
  font-size: 0.7rem;
  margin-top: 0;
  width: 80%;
`;

const Legal = styled.div`
  display: flex;
  flex-direction: column;
`;

const TosLinkOut = styled(Link)`
    text-decoration: underline;
    color: #3fb39d;

  &:hover {
    cursor: pointer;
  }
`;

const LegalSingle = styled.div`
    margin-top: 3rem;
    width: 100%;
`;

const validateConsent = (value) => {
  let error;
  if(value !== true){
    error = "Consent is Required"
  }
  return error;
}

function LegalInformation(props) {
  const [TosIsOpen, setTosIsOpen] = useState(false);

  const openTos = () => {
    setTosIsOpen((s) => !s);
  };

  const tos = (
    <TosInfo>
      By checking this box, I hereby consent to the{" "}
      <TosLink onClick={() => openTos()}>"E-Sign Agreement"</TosLink>, the{" "}
      <TosLink onClick={() => openTos()}>
        "Credit Authorization Agreement"
      </TosLink>
      , the <TosLinkOut to="/terms-of-service">Terms of Service</TosLinkOut> and{" "}
      <TosLinkOut to="/privacy-policy">Privacy Policy</TosLinkOut>, and I am
      providing written consent under the Fair Credit Reporting Act (FCRA) for
      Fig Loans, and its partners and financial institutions to obtain consumer
      report information from my credit profile. By checking this box, I also
      expressly consent to Fig Loans sharing my information with Engine by
      MoneyLion. I agree to be contacted by Fig Loans, its partners, their
      affiliated companies and financial institutions via email and/or at the
      telephone number(s) I have provided above to explore various financial
      products and services I inquired about, including contact through
      automatic dialing systems, artificial or pre-recorded voice messaging, or
      text message.
      <br />
      Consent is required to submit this form. To use the service without
      consent, please contact Engine by MoneyLion at (800) 410-1488.
    </TosInfo>
  );

  if(props.single){
    return (
      <LegalSingle>
        <FormikControl validate={validateConsent} control="checkbox" label={tos} name="consentsToTcpa" />
        <Modal open={TosIsOpen} onClose={() => setTosIsOpen(false)}>
          <Terms />
        </Modal>
      </LegalSingle>
    )
  }

  return (
    <Legal>
      <FormikControl validate={validateConsent} control="checkbox" label={tos} name="consentsToTcpa" />
      <Modal open={TosIsOpen} onClose={() => setTosIsOpen(false)}>
        <Terms />
      </Modal>
    </Legal>
  );
}

export default LegalInformation;
