import React, { useContext } from "react";
import { StyledMenu } from "./Styles/Menu";

import { NavList, NavItem } from "./Styles/Menu";

import { Link } from "react-router-dom";

import { Link as ScrollLink } from "react-scroll";

import { AppContext } from "../../../Context/Context";

import { copy } from "../../../Helpers/CopyText";

import { LanguageSelector } from "../../Header/Header";

const Menu = ({ open }) => {
  let context = useContext(AppContext);
  let language = context.language;

  let copyText = copy(language);

  return (
    <StyledMenu open={open}>
      <NavList>
        <NavItem>
          <Link className="nav-link" to="/personal-loan/application/0">
            {copyText.hamburger.link1}
          </Link>
        </NavItem>
        <NavItem>
          {/* <Link className="nav-link" to="/">Refinance</Link> */}
          <ScrollLink to="FAQ" smooth={true} duartion={300}>
            {copyText.hamburger.link2}
          </ScrollLink>
        </NavItem>
        <NavItem>
          {/* <Link className="nav-link" to="/">Credit Cards</Link> */}
          <ScrollLink to="Reviews" smooth={true} duartion={300}>
            {copyText.hamburger.link3}
          </ScrollLink>
        </NavItem>
        <NavItem>
          <Link className="nav-link" to="/blog">
            {copyText.hamburger.link4}
          </Link>
        </NavItem>
        <NavItem>
          <LanguageSelector context={context} />
        </NavItem>
        <NavItem>
          {/* <Link className="nav-link" to="/">
              Savings
            </Link> */}
        </NavItem>
        {/* <NavItem>
            <Link className="nav-link" to="/">
              Discover
            </Link>
          </NavItem> */}
      </NavList>
    </StyledMenu>
  );
};
export default Menu;
