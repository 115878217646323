import React, { useContext, useState } from "react";
import { AppContext } from "../../../../Context/Context";
import { copy } from "../../../../Helpers/CopyText";
import FormikControl from "../FormikControl";

import { FormGroup } from "./Styles/HelperComponents";

const validateDate = (value) => {
  let error;
  if (!value) {
    error = "Graduation Date Required!";
    return error;
  }
  if (value.length < 10) {
    error = "Graduation Date Required";
  } else if (value.length === null) {
    error = "Graduation Date Required";
  }
  return error;
};

function EducationInformation(props) {
  const [level, setLevel] = useState(false);
  const [degree, setDegree] = useState(false);

  const [universities, setUniversities] = useState([]);

  const headers = {
    Accept: "application/json",
    Authorization:
      "Bearer 4a805a1c-0765-41b5-8a4b-39a395faf543_4d5a8ff6-8dcd-417f-9032-7c6e69ff32d9",
  };

  if (universities.length < 1) {
    fetch(`https://api.evenfinancial.com/leads/universities/?limit=7000`, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        if (!Object.values) {
          setUniversities(Object.keys(data).map((e) => data[e]));
        } else {
          setUniversities(Object.values(data));
        }
      });
  }

  const validateEducation = (value) => {
    let error;
    if (value === "") {
      error = "Education Required";
    }
    if (value === "high_school") {
      setDegree(false);
    } else {
      setDegree(true);
    }
    if (value === "doctorate") {
      setLevel(true);
    } else {
      setLevel(false);
    }
    return error;
  };

  const validateUniversity = (value) => {
    let error;
    let uniCheck = universities.filter((i) => {
      return i.toLowerCase().match(value.trim().toLowerCase());
    });
    if (!uniCheck) {
      error = "University Required";
    }
    if (uniCheck.length <= 0) {
      error = "University Required";
    }
    return error;
  };

  let context = useContext(AppContext);
  let language = context.language;
  let copyText = copy(language);

  const educationLevelOptions = [
    { key: "High School", value: "high_school" },
    { key: "Associate's Degree", value: "associate" },
    { key: "Bachelor's Degree", value: "bachelors" },
    { key: "Master's Degree", value: "masters" },
    { key: "Doctorate", value: "doctorate" },
    { key: "Other Graduate Degree", value: "other_grad_degree" },
    { key: "Other", value: "other" },
  ];

  const degreeTypeOptions = [
    { key: "Medicine", value: "doctor_of_medicine" },
    { key: "Osteopathic Medicine", value: "doctor_of_osteopathic_medicine" },
    { key: "Optometry", value: "doctor_of_optometry" },
    { key: "Dental Medicine", value: "doctor_of_dental_medicine" },
    { key: "Dental Surgery", value: "doctor_of_dental_surgery" },
    { key: "Veterinary Medicine", value: "doctor_of_veterinary_medicine" },
    { key: "Pharmacy", value: "doctor_of_pharmacy" },
    { key: "Arts", value: "master_of_arts" },
    { key: "Science", value: "master_of_science" },
    { key: "Research", value: "master_of_research" },
    { key: "Studies", value: "master_of_studies" },
    {
      key: "Business Administration",
      value: "master_of_business_administration",
    },
    { key: "Library Science", value: "master_of_library_science" },
    { key: "Public Administration", value: "master_of_public_administration" },
    { key: "Public Health", value: "master_of_public_health" },
    { key: "Law", value: "master_of_laws" },
    { key: "Arts Liberal Studies", value: "master_of_arts_liberal_studies" },
    { key: "Fine Arts", value: "master_of_fine_arts" },
    { key: "Music", value: "master_of_music" },
    { key: "Education", value: "master_of_education" },
    { key: "Engineering", value: "master_of_engineering" },
    { key: "Architecture", value: "master_of_architecture" },
    { key: "Other", value: "other" },
  ];

  if (props.single) {
    return (
      <FormGroup>
        <FormikControl
          extraClass="single-select"
          control="select"
          options={educationLevelOptions}
          label={copyText.educationInformation.label1}
          name="educationLevel"
          validate={validateEducation}
        />
      </FormGroup>
    );
  }

  switch (props.product) {
    case "personal-loan":
      return (
        <>
          <FormikControl
            control="select"
            options={educationLevelOptions}
            label={copyText.educationInformation.label1}
            name="educationLevel"
            validate={validateEducation}
          />
        </>
      );
    case "student-loan-refinance":
      return (
        <>
          <FormikControl
            control="select"
            options={educationLevelOptions}
            label={copyText.educationInformation.label1}
            name="educationLevel"
            validate={validateEducation}
            setLevel={setLevel}
          />
          <FormikControl
            control="dateinput"
            label={copyText.educationInformation.label2}
            name="graduationDate"
            validate={validateDate}
            maxLength={10}
            ph={"MM/DD/YYYY"}
          />
          {degree ? (
            <FormikControl
              control="search"
              label={copyText.educationInformation.label3}
              name="universityAttended"
              ph="Start Typing..."
              universities={universities}
              validate={validateUniversity}
            />
          ) : null}
          {level ? (
            <FormikControl
              control="select"
              options={degreeTypeOptions}
              label={copyText.educationInformation.label4}
              name="graduateDegreeType"
            />
          ) : null}
        </>
      );
    default:
      return null;
  }
}

export default EducationInformation;
