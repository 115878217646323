import React, {useState, useContext} from "react";
import {
  Container,
  ScenarioHeader,
  Scenario,
  ScenarioContainer,
  Button,
} from "./Styles/Scenarios";

import { Link } from "react-router-dom";

import {copy} from '../../Helpers/CopyText'

import Carousel from 'react-elastic-carousel'

import './Styles/Scenarios.css'
import {AppContext} from '../../Context/Context'


function Scenarios() {
  let context = useContext(AppContext)
  let language = context.language;
  let copyText = copy(language)

  const auto = 'https://i.imgur.com/NWaATuc.jpg'
  const home = 'https://i.imgur.com/Y0IKHak.jpg'
  const credit = 'https://i.imgur.com/WSu8PWl.jpg'
  const student = 'https://i.imgur.com/l6hPk3F.jpg'

  const [isMobile, setIsMobile] = useState(false);

  const items = [
      {src: 'https://i.imgur.com/NWaATuc.jpg', link: "/personal-loan/application?purpose=auto"},
      {src: 'https://i.imgur.com/Y0IKHak.jpg', link : "/personal-loan/application?purpose=home_improvement"},
      {src: 'https://i.imgur.com/WSu8PWl.jpg', link : "/personal-loan/application?purpose=credit_card_refinance"},
      {src: 'https://i.imgur.com/l6hPk3F.jpg', link : "/personal-loan/application?purpose=student"},
    ]

  const updateSize = () => {
    setIsMobile(window.innerWidth < 950)
  }

  if(!isMobile){
    window.addEventListener('resize', updateSize)
  }

  if(isMobile){
      return(
        <>
          <ScenarioHeader mobile>
            {copyText.scenarios.header}<br/>{copyText.scenarios.header2}
           </ScenarioHeader>
          <Carousel showArrows={false} className="scenario-carousel">
            {items.map((item, i) => <Link key={i} to={item.link}><Scenario src={item.src}/></Link>)}
          </Carousel>
           <Container>
            <ScenarioContainer>
               <Link to={"/personal-loan/application/0"}>
                <Button>{copyText.scenarios.button}</Button>
              </Link>
             </ScenarioContainer>
          </Container>
        </>
      )   
  }

  return (
    <>
      <ScenarioHeader>
        {copyText.scenarios.header}<br/>{copyText.scenarios.header2}
      </ScenarioHeader>
      <Container>
        <ScenarioContainer>
          <Link to="/personal-loan/application/0?purpose=auto"><Scenario src={auto} /></Link>
          <Link to="/personal-loan/application/0?purpose=home_improvement"><Scenario src={home} /></Link>
          <Link to="/personal-loan/application/0?purpose=credit_card_refinance"><Scenario src={credit} /></Link>
          <Link to="/personal-loan/application/0?purpose=student"><Scenario src={student} /></Link>
        </ScenarioContainer>
        <ScenarioContainer>
          <Link to={"/personal-loan/application/0"}>
            <Button>{copyText.scenarios.button}</Button>
          </Link>
        </ScenarioContainer>
      </Container>
      
    </>
  );
}

export default Scenarios;
