import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
`;

export const Label = styled.label`
    font-size: 1.5rem;
    width: 60%;
    text-align: center;
    margin: 1rem auto;
`;

export const Input = styled.input`
    font-size: 1.5rem;
    width: 70%;
    height: 3rem;
    border-style: none;
    color: #525252;
    text-align: center;
    background-color: #edf6f9;
    box-shadow: inset 0 0 2px;
    border-radius: 4px;
`;

export const TextArea = styled.textarea`
    font-size: 1.5rem;
    width: 70%;
    height: 10rem;
    border-style: none;
    color: #525252;
    text-align: center;
    background-color: #edf6f9;
    box-shadow: inset 0 0 2px;
    border-radius: 4px;
    padding-top: 1rem;
`;

export const Button = styled.button`
    border-style: none;
    height: 2.5rem;
    background-color: #3cb09b;
    min-width: 40%;
    margin: 2rem auto;
    padding: 0 1rem;
    color: white;
    border-color: #363636;
    box-shadow: 0 0 3px lightgray;
    border-radius: 5px;
    font-size: 1.3rem;

    &:hover {
        background-color: #334d5d;
        cursor: pointer;
        transition: ease-in 100ms;
    }

    &:focus {
        box-shadow: 0 0 8px #3fb39d;
        outline: none;
    }
`;

export const Title = styled.h2`
    color: #3fb39d;
    font-size: 1.3rem;
`;

export const HomeText = styled.p`
    color: #363636;
    font-size: 1.3rem;
`;