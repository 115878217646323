import React, {useContext} from "react";
import {AppContext} from '../../../../Context/Context'
import {copy} from '../../../../Helpers/CopyText'
import FormikControl from "../FormikControl";
import { FormGroup } from "./Styles/HelperComponents";

export const validateEmail = value => {
  // eslint-disable-next-line
  var re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  let error;
  if(!value){
    error = 'Email Required'
    return error;
  }
  if(!(re.test(String(value).toLowerCase()))){
    error = "Please enter a valid email."
  }else if(value.length === 0){
    error = "Email is Required"
  }
  return error;
};


const validatePhone = value => {
  let error;
  if(!value || typeof value === 'undefined'){
    error = "Phone Number Required"
    return error;
  }
  if(value === ""){
    error="Phone Number Required"
    return error;
  }
  else if(value.length > 0 && value.length < 16){
    error = 'Invalid Phone Number'
  }else if(value.length === 0){
    error = 'Phone Number Required'
  }
  return error;
}

function Contact(props) {

  let context = useContext(AppContext)
  let language = context.language;
  let copyText = copy(language)

  if(props.single){
    return (
      <FormGroup>
        <FormikControl
        control="input"
        type="text"
        label={copyText.contact.label1}
        name="email"
        ph="john@example.com"
        validate={validateEmail}
        />
        <FormikControl
        control="input"
        type="text"
        label={copyText.contact.label2}
        name="primaryPhone"
        ph="(###) ### - ####"
        maxLength="16"
        validate={validatePhone}
        />
      </FormGroup>
    )
  }

  switch(props.product){
    case 'credit-card':
      return (
          <FormikControl
          control="input"
          type="text"
          label={copyText.contact.label1}
          name="email"
          ph="john@example.com"
          validate={validateEmail}
        />
      )
    default:
      return (
        <>
          <FormikControl
            control="input"
            type="text"
            label={copyText.contact.label1}
            name="email"
            ph="john@example.com"
            validate={validateEmail}
          />
          <FormikControl
            control="input"
            type="text"
            label={copyText.contact.label2}
            name="primaryPhone"
            ph="(###) ### - ####"
            maxLength="16"
            validate={validatePhone}
          />
        </>
      );
  }
}

export default Contact;
