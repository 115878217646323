import React, {useState, useEffect} from 'react'
import {
    Offer,
    OfferImage,
    OfferTerm,
    OfferAmount,
    OfferPayment,
    OfferButton,
    OfferLink,
    OfferInfoGroup,
    OfferInfoTitle,
    OfferImageLink,
    OfferDisplay,
    MoreInfoContainer,
    MoreInfoGroup,
    DisclaimerText,
    DropDown,
    DropText,
    CurrentOfferNum,
    BadgeDisplayContainer,
    MoreInformation
} from './Styles/LoanOffer'

import './Styles/LoanOffer.css'

import {recordClickThrough} from '../../../Helpers/apicall'

import {InfoTable, Banner, Badge, InfoTableHead, InfoTableRow, ViewMore, InfoTableData, InfoTableBody, InfoTableHeading} from './Styles/LoanOffer'
import ReactTooltip from "react-tooltip";
import {BiBadgeCheck, BiLockAlt} from 'react-icons/bi'
import {BsFillInfoCircleFill} from 'react-icons/bs'
import {RiMoneyDollarCircleLine} from 'react-icons/ri'


import {AiOutlineDownCircle, AiOutlineUpCircle, AiFillCaretLeft, AiFillCaretRight} from 'react-icons/ai'

import {formatInput} from '../../../Helpers/Formatting'

import OfferBox from './OfferBox'

export const Disclaimer = ({ content, name, display }) => {
  return (
    <>
      <DisclaimerText data-tip data-for={name}>
        {display && display.length > 1 ? display : 'Disclaimer and Info'}
      </DisclaimerText>
      <ReactTooltip className="disclaimer-tooltip" id={name} type="dark">
        <span>{content}</span>
      </ReactTooltip>
    </>
  );
};

const SubDisclaimer = ({ content, name }) => {
    return (
      <>
        <DisclaimerText data-tip data-for={name + '1'}>
            <BsFillInfoCircleFill className="disclaimer-info"/>
        </DisclaimerText>
        <ReactTooltip className="disclaimer-tooltip" id={name + '1'} type="dark">
          <span>{content}</span>
        </ReactTooltip>
      </>
    );
  };

function LoanOffer({master, preview, i, offers}) {
    // Handle expansion of offer component
    const [open, setOpen] = useState(false);
    const [view, setView] = useState(false);
    const [approved, setApproved] = useState(false)
    const [qualified, setQualified] = useState(false)
    const [offerNum, setOfferNum] = useState(0)
    const [isSub, setIsSub] = useState(false)
    const [isSecured, setIsSecured] = useState(false)
    const [isLineOfCredit, setIsLineOfCredit] = useState(false)

    const handleClick = () => {
        setOpen(s => !s)
    }

    const handleGTM = (loanAmount, uuid, url) => {
        recordClickThrough(uuid, url)
        if(window.dataLayer){
            window.dataLayer.push({
                event : 'Offer Click',
                loanAmount : loanAmount
            })
        }
    }

    console.log(offers);

    // Abstract all information from loan offer JSON
    let url = offers[offerNum].originator.images[0].url;
        url = url.includes("https:") ? url : "https://" + url;
    let payment = preview ? offers[offerNum].minMonthlyPayment : offers[offerNum].monthlyPayment;
    let term = offers[offerNum].termLength;
    let apr = preview ? `${offers[offerNum].minApr} - ${offers[offerNum].maxApr}` : offers[offerNum].meanApr;
    let loanAmount = offers[offerNum].maxAmount;
    let uuid = master.uuid
    let offerUrl = offers[offerNum].url
    let maxPayment = offers[offerNum].maxTotalPayment ? '$' + offers[offerNum].maxTotalPayment.toString() : 'Floating'
    let termUnit = offers[offerNum].termUnit;

    let subDisclaimer = offers[offerNum].productSubTypeDisclaimer ? offers[offerNum].productSubTypeDisclaimer : '';
    let description = offers[offerNum].originator.description ? offers[offerNum].originator.description : null

    useEffect(() => {
        if(offers[offerNum].preQualified === true && !qualified){
            setQualified(true)
            if(offers[offerNum].preApproved){
                setApproved(true)
            }
        }
        let sub = offers[offerNum].productSubType;
        if(sub && sub.length >= 1) {
            setIsSub(true)
            if(sub === 'line_of_credit'){
                setIsLineOfCredit(true)
            }else if(sub === 'secured_loan'){
                setIsSecured(true)
            }
        }
    }, [offers, offerNum, qualified])

    const handleNavigation = (navValue) => {
        let steps = offers.length - 1;
        if(offerNum >= 1 && navValue === -1){
            setOfferNum(s => s - 1)
        }
        if(offerNum < steps && navValue === 1){
            setOfferNum(s => s + 1)
        }
        if(offerNum === steps && navValue === 1){
            setOfferNum(0)
        }
        if(offerNum === 0 && navValue === -1){
            setOfferNum(steps)
        }
    }

    if(offers[offerNum].termUnit === 'open'){
        termUnit = 'Open'
        term = ''
    }
    if(term > 1){
        termUnit = termUnit + 's'
    }

    return (
    <>
        <Offer key={i}>
        <BadgeDisplayContainer>
                {
                    qualified && !approved ? <Banner><Badge>Pre-Qualified<BiBadgeCheck className="pre"/></Badge></Banner> : null
                }
                {
                    approved ? <Banner><Badge>Pre-Approved<BiBadgeCheck className="pre"/></Badge></Banner> : null
                }
                {
                    isSub && (isSecured || isLineOfCredit) ? <Banner><Badge sub>{isSecured ? <BiLockAlt className="pre"/> : <RiMoneyDollarCircleLine className="pre"/>}{isSecured ? 'Secured Loan' : 'Line of Credit'}<SubDisclaimer content={subDisclaimer} name={offers[offerNum].originator.name} className="disclaimer-info"/></Badge></Banner> : null
                }
            </BadgeDisplayContainer>
            <OfferDisplay>
                <OfferImageLink href={offerUrl}><OfferImage src={url}/></OfferImageLink>
                <OfferInfoGroup>
                    <OfferTerm>{term + ' ' + termUnit}</OfferTerm>
                    <OfferInfoTitle>{offers[offerNum].termDescription}</OfferInfoTitle>
                </OfferInfoGroup>
                <OfferInfoGroup>
                    <OfferAmount>{formatInput(loanAmount.toString(), 'loanAmount')}</OfferAmount>
                    <OfferInfoTitle>Loan Amount</OfferInfoTitle>
                </OfferInfoGroup>
                <OfferInfoGroup>
                    <OfferPayment>${payment.toFixed(2)}</OfferPayment>
                    <OfferInfoTitle>Est. Payment</OfferInfoTitle>
                </OfferInfoGroup>
                <OfferLink onClick={() => handleGTM(loanAmount, uuid, offerUrl)} target='_blank' href={offerUrl}>
                    <OfferButton id="conversion">CONTINUE</OfferButton>
                </OfferLink>
            </OfferDisplay>
            {
                  open ? <DropDown><DropText onClick={() => handleClick()}>Details {offers.length > 1 ? ' and Additional Offers' : null}</DropText><AiOutlineUpCircle style={{fontSize : '1.3rem'}} onClick={() => handleClick()} className="more-info"/></DropDown> : <DropDown><DropText onClick={() => handleClick()}>Details {offers.length > 1 ? ' and Additional Offers' : null}</DropText><AiOutlineDownCircle onClick={() => handleClick()} style={{fontSize : '1.3rem'}} className="more-info"/></DropDown>
            }
            {open ? <MoreInfoContainer >
                {offers.length > 1 ? <AiFillCaretLeft className="nav-arrow hide-nav" onClick={() => handleNavigation(-1)}/> : null}
                <MoreInfoGroup id={i} show={open}>
                    <CurrentOfferNum className="hide-nav">Current Offer: {offerNum + 1} / {offers.length}</CurrentOfferNum>
                    <InfoTable>
                        <InfoTableHead>
                            <InfoTableRow>
                                <InfoTableHeading>Loan Provider</InfoTableHeading>
                                <InfoTableHeading>Loan Term</InfoTableHeading>
                                <InfoTableHeading>Loan Amount</InfoTableHeading>
                                <InfoTableHeading>APR</InfoTableHeading>
                                <InfoTableHeading>Total Cost</InfoTableHeading>
                            </InfoTableRow>
                        </InfoTableHead>
                        <InfoTableBody>
                            <InfoTableRow>
                                <InfoTableData>{offers[offerNum].originator.name}</InfoTableData>
                                <InfoTableData>{term} {termUnit}</InfoTableData>
                                <InfoTableData>{formatInput(offers[offerNum].maxAmount, 'loanAmount')}</InfoTableData>
                                <InfoTableData>{apr}%</InfoTableData>
                                <InfoTableData>{maxPayment.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}</InfoTableData>
                            </InfoTableRow>
                        </InfoTableBody>
                    </InfoTable>
                    <OfferLink more target='_blank' href={offerUrl}>
                        <OfferButton onClick={() => handleGTM(loanAmount, uuid, offerUrl)} id="conversion">CONTINUE</OfferButton>
                    </OfferLink>
                {
                 offers.length > 1 ? <ViewMore onClick={() => {setView(s => !s)}}>See All Offers From {offers[offerNum].originator.name}</ViewMore>: null
                }
                {
                    view ? <OfferBox offers={offers} setView={setView}/> : null
                }
                <Disclaimer
                    key={i + 1}
                    name={offers[offerNum].originator.name}
                    style={{ width: "100%" }}
                    content={offers[offerNum].originator.disclaimer}
                />
                {description && description.length > 1 ? <MoreInformation>
                    <p style={{fontWeight: 'bold', textAlign: 'left', width: '100%'}}>More Information</p>
                    {description.replace(/<[^>]*>?/gm, '')}
                </MoreInformation> : null}
                </MoreInfoGroup>
                {offers.length > 1 ? <AiFillCaretRight className="nav-arrow hide-nav" onClick={() => handleNavigation(1)}/>: null}
            </MoreInfoContainer>
             : null}
        </Offer>
    </>
    )
}

export default LoanOffer
