import React, {useState, useContext} from "react";
import {
  HeaderContainer,
  LogoContainer,
  NavList,
  NavItem,
  NavContainer,
  LogoImg,
  BurgerContainer,
  Logo,
  NavLink,
  Language,
  LanguageContainer,
} from "./Styles/Header";


import "./Styles/Header.css";

import { Link, useLocation } from "react-router-dom";


import logo from "../../images/logo-1.png";

import Burger from '../Hamburger/Burger'

import Menu from '../Hamburger/Menu'

import {AppContext} from '../../Context/Context'

import {copy} from '../../Helpers/CopyText'

import {MdLanguage} from 'react-icons/md'

export const LanguageSelector = ({context}) => {
  return (
    <LanguageContainer>
      <MdLanguage className="language-icon"/>
      <Language onClick={() => context.setLanguage('english')}>EN</Language>
      <Language onClick={() => context.setLanguage('spanish')}>ES</Language>
    </LanguageContainer>
  )
}

function Header({hide}) {

  let context = useContext(AppContext);

  let language = context.language

  let copyText = copy(language)

  const[open, setOpen] = useState(false)

  const location = useLocation();
  
  return (
    <HeaderContainer>
      <LogoContainer hide={hide}>
      <Link className="nav-link" to="/">
        <div className="logo">
          <LogoImg src={logo} />
          <Logo>Marketplace</Logo>
        </div>
      </Link>
      <BurgerContainer>
        <Burger  hide={hide} open={open} setOpen={setOpen}/>
      </BurgerContainer>
      </LogoContainer>
      <Menu open={open}/>
      <NavContainer>
        <NavList hide={hide}>
          {location.pathname !== '/' ? <NavItem>
            <Link className="nav-link" to="/">{copyText.nav.homeLink}</Link>
          </NavItem> : null}
          <NavItem>
            <Link className="nav-link" to="/personal-loan/application/0">
              {copyText.nav.link1}
            </Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link" to="/savings-account/">
              {copyText.nav.link2}
            </Link>
          </NavItem>
          {location.pathname === '/' ? <NavItem>
            <NavLink to="FAQ" smooth={true} duartion={300}>{copyText.nav.link3}</NavLink>
          </NavItem> : null}
          {location.pathname === '/' ? <NavItem>
            <NavLink to="Reviews" smooth={true} duartion={300}>{copyText.nav.link4}</NavLink>
          </NavItem> : null}
          <NavItem>
            <Link className="nav-link" to="/blog">{copyText.nav.link5}</Link>
          </NavItem>
          <NavItem>
            <LanguageSelector context={context}/>
          </NavItem>
        </NavList>
      </NavContainer>
    </HeaderContainer>
  );
}

export default Header;
