import React, {useContext} from "react";
import {AppContext} from '../../../../Context/Context'
import {copy} from '../../../../Helpers/CopyText'
import FormikControl from "../FormikControl";

import { FormGroup } from "../FormComponents/Styles/HelperComponents";


const hasNumber = (value) => {
  return /\d/.test(value);
}

const onlyLetters = (value) => {
  var letters = /^[A-Za-z][A-Za-z\s]*$/;
  return letters.test(value)
}

const validateName = (value) => {
  let error;
  if(value.replace(/\s+/g, '') === ''){
    error = 'Name Required'
  }else if(hasNumber(value)){
    error = "Please Enter a Valid Name"
  }else if(!onlyLetters(value)){
    error = "Please Enter a Valid Name"
  }
  return error;
}

const validateLastName = (value) => {
  let error;
  if(value === ''){
    error = 'Last Name Required'
  }else if(hasNumber(value)){
    error = "Please Enter a Valid Last Name"
  }else if(!onlyLetters(value)){
    error = "Please Enter a Valid Last Name"
  }
  return error;
}


function Name(props) {

  let context = useContext(AppContext)
  let language = context.language
  let copyText = copy(language)

  if(props.single){
    return(
      <FormGroup>
        <FormikControl
        control="input"
        type="text"
        label={copyText.name.label1}
        name="firstName"
        ph="John"
        validate={validateName}
      />
      <FormikControl
        control="input"
        type="text"
        label={copyText.name.label2}
        name="lastName"
        ph="Doe"
        validate={validateLastName}
      />
      </FormGroup>
    )
  }

  return (
    <>
      <FormikControl
        control="input"
        type="text"
        label={copyText.name.label1}
        name="firstName"
        ph="John"
        validate={validateName}
      />
      <FormikControl
        control="input"
        type="text"
        label={copyText.name.label2}
        name="lastName"
        ph="Doe"
        validate={validateLastName}
      />
    </>
  );
}

export default Name;
