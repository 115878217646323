import React from "react";

import styled from "styled-components";

const TitleText = styled.h2`
  font-size: 2.4rem;
  color: #3fb39d;
  margin-bottom: 0;
  text-align: center;
`;
const TitleInfo = styled.p`
  margin-bottom: 2rem;
  font-size: 1.4rem;
  text-align: center;
`;

function Title(props) {
  const { text, info } = props;
  return (
    <>
      <TitleText>{text}</TitleText>
      <TitleInfo>{info}</TitleInfo>
    </>
  );
}

export default Title;
