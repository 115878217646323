import React from "react";

import { DisclaimerContainer, Disclaimer } from "./Styles/Disclosure";

function Disclosure() {
  return (
    <DisclaimerContainer>
      <Disclaimer>
        <strong>APR Disclosure:</strong> The Annual Percentage Rate is the rate at which your
        loan accrues interest. It is based upon the amount of your loan, cost of
        the loan, term of the loan, repayment amounts and timing of payments and
        payoff. By law, the lender or lending partner/platform must show you the
        APR before you enter into the loan. States have laws that may limit the
        APR that the lender or lending partner/platform can charge you. Rates
        will vary based on your credit worthiness, loan size, and other
        variables, with the lowest rates available to customers with excellent
        credit. Minimum and maximum loan amounts and APRs may vary according to
        state law and lender or lending partner/platform. We recommend that you
        read the lender’s and/or lending partner’s/platform’s personal terms and
        conditions in full before proceeding for a personal loan.
      </Disclaimer>
      <Disclaimer>
        <strong>Income Disclosure:</strong> Your yearly income before taxes, alimony, child
        support, or separate maintenance is optional and does not need to be
        included if you do not wish it to be considered as a basis for repaying
        the loan. Increase any non-taxable income by 25%.
      </Disclaimer>
      <Disclaimer>
        <strong>Representative Example:</strong> If you borrow $5,000 on a 3 year repayment term and
        at a 10% APR, the monthly repayment will be $161.34. Total repayment will be
        $5,808.24. Total interest paid will be $808.24.
      </Disclaimer>
      <Disclaimer>
        The offers that appear are from companies which Fig Tech, Inc.,
        and its partners receive compensation. This compensation may influence
        the selection, appearance, and order of appearance of the offers listed
        below. However, this compensation also facilitates the provision by Fig Tech of certain services to you at no charge. The offers shown
        below do not include all financial services companies or all of their
        available product and service offerings.
      </Disclaimer>
    </DisclaimerContainer>
  );
}

export default Disclosure;
