import React from 'react';
import { StyledBurger } from './Styles/Burger';

const Burger = ({open, setOpen, hide}) => {
  return (
    <StyledBurger hide={hide} open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div /> 
    </StyledBurger>
  )
}

export default Burger;