import React from 'react'
import {Helmet} from 'react-helmet'

function MetaDecorator({title, content, canonical}) {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name='description' content={content}/>
            {
                canonical ? <link rel="canonical" href="http://figloans.com/blog" /> : null
            }
        </Helmet>
    )
}

export default MetaDecorator;