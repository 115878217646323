import styled from "styled-components";

export const ErrorMessage = styled.div`
  color: #e37262;
  font-size: 1.2em;
  font-style: italic;
  text-align: center;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;


export const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const ResultContainer = styled.div`
  background-color: white;
  box-shadow: 0 0 6px lightgray;
  border-radius: 10px;
  position: absolute;
  z-index: 9999;
  margin-top: 110px;
  padding: 15px;
`;

export const Result = styled.p`
  text-align: left;
  width: 100%;
  margin: .2rem 0;
  border-bottom: 1px solid gray;
  padding: .2rem 0;

  &:hover {
    color: #3fb39d;
    cursor: pointer;
  }
`;