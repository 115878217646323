import React, {createContext, useState} from "react";
import {initialLoanApp, initialStudentLoanRefinance, initialCreditCard, initialSavingsAccount} from '../Helpers/FormData'


export const AppContext = createContext();

const Context = (props) => {

    if(!localStorage.getItem('dns') && localStorage.getItem('dns') !== 'false'){
        localStorage.setItem('dns', true)
    }

    const [consent, setConsent] = useState(true)

    const [personalLoan, setPersonalLoan] = useState(initialLoanApp);

    const [studentLoanRefinance, setStudentLoanRefinance] = useState(initialStudentLoanRefinance);

    const [creditCard, setCreditCard] = useState(initialCreditCard)

    const [savingsAccount, setSavingsAccount] = useState(initialSavingsAccount)

    const [product, setProduct] = useState('personal-loan')

    const [productCode, setProductCode] = useState('personalLoan')

    const [userIp, setUserIp] = useState('');

    const [language, setLanguage] = useState('english')

    const [source , setSource] = useState('')
    const [medium , setMedium] = useState('')
    const [campaign , setCampaign] = useState('')
    const [content , setContent] = useState('')
    const [term , setTerm] = useState('')

    let queryString = (window.location.search)

    const urlParams = new URLSearchParams(queryString);

    const entries = urlParams.entries();

    for(const entry of entries) {
        let key = entry[0];
        let value = entry[1];
        if(key === 'utm_source' && source === ''){
            setSource(value)
            localStorage.setItem('source', value)
        }
        if(key === 'utm_medium' && medium === ''){
            setMedium(value)
            localStorage.setItem('medium', value)
        }
        if(key === 'utm_campaign' && campaign === ''){
            setCampaign(value)
            localStorage.setItem('campaign', value)
        }
        if(key === 'utm_content' && content === ''){
            setContent(value)
            localStorage.setItem('content', value)
        }
        if(key === 'utm_term' && term === ''){
            setTerm(value)
            localStorage.setItem('term', value)
        }
    }

    let utm = {
        utm_source : source,
        utm_medium : medium,
        utm_campaign : campaign,
        utm_content : content,
        utm_term : term,
    }

    if (!userIp) {
      let url = 'https://app.figloans.com/api/shallow_ip_lookup'
      const postHeader = {
        "Content-Type": "application/json",
        Accept: "application/json",
      }

      fetch(url, {
          method: 'GET',
          headers: postHeader,
      })
          .then(response => response.json())
          .then(data => {
              setUserIp(data.ip)})
    }

    return(
        <AppContext.Provider value={{
            consent,
            setConsent,
            personalLoan,
            setPersonalLoan,
            studentLoanRefinance,
            setStudentLoanRefinance,
            savingsAccount,
            setSavingsAccount,
            setCreditCard,
            creditCard,
            userIp,
            setUserIp,
            language,
            setLanguage,
            product,
            setProduct,
            utm,
            productCode,
            setProductCode,
        }}>
            {props.children}
        </AppContext.Provider>
    )
}


export default Context;
