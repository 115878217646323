import React from "react";

import { ModalContainer, Overlay } from "./Styles/Modal";

function Modal({ children, open, onClose }) {
  if (!open) return null;

  return (
    <>
      <Overlay onClick={onClose} />
      <ModalContainer>
        <button onClick={onClose}>X</button>
        {children}
      </ModalContainer>
    </>
  );
}

export default Modal;
